import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { ITax, IUser, NGRX_NO_ACTION, Role, RV, Tax, User } from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import * as NetworkActions from '@curbnturf/network/src/lib/network/+state/network.actions';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { GlobalSpinnerService } from '@curbnturf/ui/src/lib/spinner/global-spinner.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { UserService } from '../user.service';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';

export const loadUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.loadUser),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      withLatestFrom(store.select(UserSelectors.getLoaded)),
      mergeMap(([[action, currentUser], loaded]) => {
        if (currentUser?.email && currentUser?.id === action.id && loaded && !action.force) {
          return from([NGRX_NO_ACTION]);
        }

        return userService.fetch({ id: action.id, refId: action.refId }).pipe(
          map((user: IUser) => UserActions.userLoaded({ user })),
          catchError((error) => [UserActions.userLoadError({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const loadUsers$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.loadUsers),
      switchMap((action) => {
        // TODO: check if it is already loaded before loading it again
        return userService.fetchAll(action.request).pipe(
          map((users: IUser[]) => UserActions.usersLoaded({ users })),
          catchError((error) => [UserActions.userLoadError({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const loadUserFavorites$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.loadUserFavorites),
      switchMap((action) => {
        if (action.user?.id) {
          // TODO: check if it is already loaded before loading it again
          return userService.fetchFavorites(action.user?.id).pipe(
            map((user: IUser) =>
              UserActions.userFavoritesLoaded({
                id: user.id || 0,
                poiFavorites: user.poiFavorites || [],
                siteFavorites: user.siteFavorites || [],
              }),
            ),
            catchError((err) => [UserActions.userLoadError({ error: err })]),
          );
        }

        return [
          UserActions.userLoadError({
            error: 'User must be logged in to load their favorites.',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const loadCurrentUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.loadCurrentUser),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, user]) => {
        if (user?.id) {
          return userService.fetch({ id: user.id }).pipe(
            map((user: IUser) => UserActions.currentUserLoaded({ user })),
            catchError((error) => [UserActions.userLoadError({ error })]),
          );
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
      catchError((error) => [UserActions.userLoadError({ error })]),
    ),
  { functional: true },
);

export const reloadCurrentUser$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.reloadCurrentUser),
      switchMap(() =>
        store.select(UserSelectors.getCurrentUser).pipe(
          take(1),
          map(() => UserActions.loadCurrentUser()),
          catchError((error) => [UserActions.userLoadError({ error })]),
        ),
      ),
      catchError((error) => [UserActions.userLoadError({ error })]),
    ),
  { functional: true },
);

export const createUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.createUser),
      switchMap((action) => {
        const newUser: IUser = {
          ...action.payload,
          authAccessToken: undefined,
          authIdToken: undefined,
          authRenewToken: undefined,
          authExpires: undefined,
        };
        return userService.create(newUser).pipe(
          mergeMap((user: IUser) => {
            if (action.payload.loginAfter) {
              return [
                UserActions.loginUser({
                  payload: { ...action.payload, ...user, loginAfter: true },
                }),
              ];
            }
            return [
              AlertActions.displayAlert({
                title: 'User Registered',
                body: `A user has been registered with ${newUser.email} as their email.`,
              }),
              UserActions.userCreated({ user }),
            ];
          }),
          catchError((error) => [UserActions.userCreateError({ error })]),
        );
      }),
    ),
  { functional: true },
);

export const createUserError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.userCreateError),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Creating User',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

/**
 * Everytime an RV is updated the user is set to the current user. If there is ever
 * a situation where the RV can be editted by someone else we should instead change
 * it to force the current user onto the initial RV at creation time.
 */
export const updateRv$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.updateRV),
      switchMap((action) => {
        if (action.userId) {
          const userId = action.userId;
          return store.select(UserSelectors.getAllUserEntities).pipe(
            take(1),
            switchMap((users: { [id: number]: User }) => {
              const user = users[userId];

              if (!user) {
                return [
                  UserActions.userUpdateError({
                    error: 'Unable to load user to update RV',
                  }),
                ];
              }

              const updateRv = new RV({
                ...action.rv,
                user: { id: user.id },
              });
              const rvs: RV[] = [];
              if (user.rvs) {
                rvs.push(...user.rvs);
              }
              if (!updateRv.id) {
                delete updateRv.id;
              }

              const index = rvs.findIndex((rv) => rv.id === updateRv.id);
              rvs[index] = updateRv;

              const updateUser = { ...user, rvs };
              return [
                UserActions.updateUser({
                  user: updateUser,
                }),
              ];
            }),
            catchError((error) => [UserActions.userCreateError({ error })]),
          );
        }

        return store.select(UserSelectors.getCurrentUser).pipe(
          take(1),
          switchMap((currentUser: User) => {
            const updateRv = new RV({
              ...action.rv,
              user: { id: currentUser.id },
            });
            const rvs: RV[] = [];
            if (currentUser.rvs) {
              rvs.push(...currentUser.rvs);
            }
            if (!updateRv.id) {
              delete updateRv.id;
            }

            const index = rvs.findIndex((rv) => rv.id === updateRv.id);
            rvs[index] = updateRv;

            const updateUser = { ...currentUser, rvs };
            return [
              UserActions.updateUser({
                user: updateUser,
              }),
            ];
          }),
          catchError((error) => [UserActions.userCreateError({ error })]),
        );
      }),
    ),
  { functional: true },
);

/**
 * The RV may not have been saved to the server so let the reducer remove it.
 * If there is an ID it means it has been saved to the server and so we update the user on the server.
 */
export const removeRv$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.removeRV),
      switchMap((action) => {
        const removeRv = action?.rv;
        if (!removeRv || !removeRv.id) {
          return of(NGRX_NO_ACTION);
        }

        const userId = action?.userId;
        if (userId) {
          return store.pipe(select(UserSelectors.getAllUserEntities)).pipe(
            take(1),
            switchMap((users: { [id: number]: User }) => {
              const user = users[userId];

              if (!user) {
                return [
                  UserActions.userUpdateError({
                    error: 'Unable to load user to update RV',
                  }),
                ];
              }

              const rvs: RV[] = [];
              if (user.rvs) {
                rvs.push(...user.rvs);
              }
              const index = rvs.findIndex((rv) => rv.id === removeRv.id);
              if (index >= 0) {
                rvs.splice(index, 1);
              }
              let selectedRv = undefined;
              if (rvs.length > 0) {
                selectedRv = rvs[0];
              }
              const updateUser = { ...user, rvs, selectedRv };
              return [
                UserActions.updateUser({
                  user: updateUser,
                }),
                UserActions.selectRV({
                  rv: undefined,
                }),
              ];
            }),
            catchError((error) => [UserActions.userCreateError({ error })]),
          );
        }
        return store.pipe(select(UserSelectors.getCurrentUser)).pipe(
          take(1),
          switchMap((currentUser: User) => {
            const rvs: RV[] = [];
            if (currentUser.rvs) {
              rvs.push(...currentUser.rvs);
            }
            const index = rvs.findIndex((rv) => rv.id === removeRv.id);
            if (index >= 0) {
              rvs.splice(index, 1);
            }
            let selectedRv = undefined;
            if (rvs.length > 0) {
              selectedRv = rvs[0];
            }
            const updateUser = { ...currentUser, rvs, selectedRv };
            return [
              UserActions.updateUser({
                user: updateUser,
              }),
              UserActions.selectRV({
                rv: undefined,
              }),
              AlertActions.displayAlert({
                title: 'RV Removed',
                body: `RV record "${action.rv.name || 'Unnamed RV'}" has been removed.`,
                level: 'error',
              }),
            ];
          }),
          catchError((error) => [UserActions.userCreateError({ error })]),
        );
      }),
    ),
  { functional: true },
);

/**
 * The RV may not have been saved to the server so let the reducer remove it.
 * If there is an ID it means it has been saved to the server and so we update the user on the server.
 */
export const selectRv$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.selectRV),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, currentUser]) => {
        const selectedRv = action.rv;
        const updateUser = { ...currentUser, selectedRv };
        return [
          UserActions.updateUser({
            user: updateUser,
          }),
        ];
      }),
      catchError((error) => [UserActions.userCreateError({ error })]),
    ),
  { functional: true },
);

/**
 * Everytime an Tax is updated the user is set to the current user. If there is ever
 * a situation where the Tax can be editted by someone else we should instead change
 * it to force the current user onto the initial Tax at creation time.
 */
export const updateTax$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.updateTax),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, currentUser]) => {
        const updateTax = new Tax({
          ...action.tax,
          user: { id: currentUser.id },
        });
        const taxes: ITax[] = [];
        if (currentUser.taxes) {
          taxes.push(...currentUser.taxes);
        }

        const index = taxes.findIndex((tax) => tax.id === updateTax.id);
        taxes[index] = updateTax;
        const updateUser = { ...currentUser, taxes };
        return [
          UserActions.updateUser({
            user: updateUser,
          }),
        ];
      }),
      catchError((error) => [UserActions.userCreateError({ error })]),
    ),
  { functional: true },
);

/**
 * The Tax may not have been saved to the server so let the reducer remove it.
 * If there is an ID it means it has been saved to the server and so we update the user on the server.
 */
export const removeTax$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.removeTax),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, currentUser]) => {
        const removeTax = action.tax;
        if (!removeTax.id) {
          return of(NGRX_NO_ACTION);
        }

        const taxes: ITax[] = [];
        if (currentUser.taxes) {
          taxes.push(...currentUser.taxes);
        }

        const index = taxes.findIndex((tax) => tax.id === removeTax.id);
        if (index >= 0) {
          taxes.splice(index, 1);
        }

        const updateUser = { ...currentUser, taxes };
        return [
          UserActions.updateUser({
            user: updateUser,
          }),
          UserActions.selectTax({
            tax: null,
          }),
        ];
      }),
      catchError((error) => [UserActions.userCreateError({ error })]),
    ),
  { functional: true },
);

export const updateUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.updateUser),
      switchMap((action) =>
        userService.update(action.user).pipe(
          switchMap((user: IUser) => [UserActions.userUpdated({ user }), UserActions.selectTax({ tax: null })]),
          catchError((error) => [UserActions.userUpdateError({ error })]),
        ),
      ),
    ),
  { functional: true },
);

export const removeUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.removeUser),
      switchMap((action) =>
        userService.remove(action.userId).pipe(
          mergeMap((removedUser: IUser) => {
            const actionArray: Action[] = [
              UserActions.userUpdated({
                user: removedUser,
              }),
            ];

            if (removedUser) {
              actionArray.push(
                AlertActions.displayAlert({
                  title: 'User removed',
                  body: `Removed User`,
                  level: 'error',
                }),
              );
            }

            return from(actionArray);
          }),
        ),
      ),
      catchError((err) => [UserActions.userUpdateError({ error: err })]),
    ),
  { functional: true },
);

export const restoreUser$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.restoreUser),
      switchMap((action) =>
        userService.restore(action.userId).pipe(
          mergeMap((restoredUser: IUser) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const actionArray: ({ type: string; payload: any } | Action)[] = [
              UserActions.userUpdated({
                user: restoredUser,
              }),
            ];

            if (restoredUser) {
              actionArray.push(
                AlertActions.displayAlert({
                  title: 'User restored',
                  body: `Restored User`,
                }),
              );
            }

            return actionArray;
          }),
        ),
      ),
      catchError((error) => [UserActions.userUpdateError({ error })]),
    ),
  { functional: true },
);

export const updateUserError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.userUpdateError),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Updating User',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const uploadUserImage$ = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
    store = inject(Store),
    spinnerService = inject(GlobalSpinnerService),
  ) =>
    actions$.pipe(
      ofType(UserActions.uploadUserImage),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      switchMap(([[action, currentUser], status]) => {
        const files = action.formData;
        if (!files) {
          return of(NGRX_NO_ACTION);
        }

        if (!currentUser.id) {
          return [
            UserActions.userImageUploadError({
              error: 'User missing id',
            }),
          ];
        }

        spinnerService.spinnerOn();

        let id = currentUser.id;

        if (currentUser.role === Role.admin && action?.data?.id) {
          id = action.data.id;
        }

        if (status.connected) {
          return userService.uploadImage(id, files).pipe(
            switchMap((updatedUser: IUser) => {
              spinnerService.spinnerOff();

              return [
                UserActions.userImageUploaded({
                  ...updatedUser,
                }),
              ];
            }),
            catchError((error) => {
              spinnerService.spinnerOff();

              return [
                UserActions.userImageUploadError({
                  error,
                }),
              ];
            }),
          );
        } else {
          return [NetworkActions.deferUserImageUpload({ photo: files })];
        }
      }),
      catchError((error) => {
        spinnerService.spinnerOff();

        return [UserActions.userImageUploadError({ error })];
      }),
    ),
  { functional: true },
);

export const userImageUploadError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.userImageUploadError),
      map((action) =>
        UserActions.userUpdateError({
          error: action.error,
        }),
      ),
    ),
  { functional: true },
);

export const removeUserImage$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.removeUserImage),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, currentUser]) => {
        if (!currentUser.id) {
          return [
            UserActions.userImageRemoveError({
              error: 'User missing id',
            }),
          ];
        }

        return userService.removeImage(currentUser.id).pipe(
          switchMap((updatedUser: IUser) => {
            return [
              UserActions.userImageRemoved({
                user: updatedUser,
              }),
            ];
          }),
          catchError((error) => [
            UserActions.userImageRemoveError({
              error,
            }),
          ]),
        );
      }),
      catchError((error) => [UserActions.userImageRemoveError({ error })]),
    ),
  { functional: true },
);

export const userImageRemoveError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.userImageRemoveError),
      map((action) =>
        UserActions.userUpdateError({
          error: action.error,
        }),
      ),
    ),
  { functional: true },
);

export const userImageRemoved$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.userImageRemoved),
      map((action) =>
        UserActions.userUpdated({
          user: action.user,
        }),
      ),
    ),
  { functional: true },
);

export const uploadRvImage$ = createEffect(
  (
    actions$ = inject(Actions),
    userService = inject(UserService),
    store = inject(Store),
    spinnerService = inject(GlobalSpinnerService),
  ) =>
    actions$.pipe(
      ofType(UserActions.uploadRvImage),
      switchMap((action) => {
        const files = action.formData;
        if (!files) {
          return of(NGRX_NO_ACTION);
        }

        const data = action.data;
        if (data.userId) {
          return userService.uploadRvImage(data.userId, data.id, files).pipe(
            switchMap((updatedUser: IUser) => {
              spinnerService.spinnerOff();

              return [UserActions.rvImageUploaded(updatedUser)];
            }),
            catchError((error) => {
              spinnerService.spinnerOff();

              return [UserActions.rvImageUploadError({ error })];
            }),
          );
        } else {
          return store.pipe(select(UserSelectors.getCurrentUser)).pipe(
            take(1),
            switchMap((currentUser: User) => {
              if (!currentUser.id) {
                return [
                  UserActions.rvImageUploadError({
                    error: 'User missing id',
                  }),
                ];
              }

              spinnerService.spinnerOn();

              return userService.uploadRvImage(currentUser.id, data.id, files).pipe(
                switchMap((updatedUser: IUser) => {
                  spinnerService.spinnerOff();

                  return [UserActions.rvImageUploaded(updatedUser)];
                }),
                catchError((error) => {
                  spinnerService.spinnerOff();

                  return [
                    UserActions.rvImageUploadError({
                      error,
                    }),
                  ];
                }),
              );
            }),
            catchError((error) => {
              spinnerService.spinnerOff();

              return [
                UserActions.rvImageUploadError({
                  error,
                }),
              ];
            }),
          );
        }
      }),
    ),
  { functional: true },
);

export const rvImageUploadError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.rvImageUploadError),
      map((action) =>
        UserActions.userUpdateError({
          error: action.error,
        }),
      ),
    ),
  { functional: true },
);

export const removeRvImage$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.removeRvImage),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([action, currentUser]) => {
        if (!currentUser.id) {
          return [
            UserActions.rvImageRemoveError({
              error: 'User missing id',
            }),
          ];
        }

        return userService.removeRvImage(currentUser.id, action.rvId).pipe(
          switchMap((updatedUser: IUser) => {
            return [
              UserActions.rvImageRemoved({
                user: updatedUser,
              }),
            ];
          }),
          catchError((error) => [
            UserActions.rvImageRemoveError({
              error,
            }),
          ]),
        );
      }),
      catchError((error) => [UserActions.rvImageRemoveError({ error })]),
    ),
  { functional: true },
);

export const rvImageRemoveError$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.rvImageRemoveError),
      map((action) =>
        UserActions.userUpdateError({
          error: action.error,
        }),
      ),
    ),
  { functional: true },
);

export const rvImageRemoved$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.rvImageRemoved),
      map((action) =>
        UserActions.userUpdated({
          user: action.user,
        }),
      ),
    ),
  { functional: true },
);

export const registerPushToken$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService), store = inject(Store)) =>
    actions$.pipe(
      ofType(UserActions.registerPushToken),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([action, user]) => {
        // Only try to save if logged in.
        if (user) {
          return userService.savePushToken(action.token).pipe(
            mergeMap(() => {
              return [UserActions.registerPushTokenSuccess()];
            }),
            catchError((error) => {
              return [UserActions.registerPushTokenFailed({ error })];
            }),
          );
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
    ),
  { functional: true },
);

export const loadAccountBalanceTransactions$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.loadAccountBalanceTransactions),
      switchMap((action) => {
        if (action.user.id) {
          return userService.fetchAccountBalanceTransactions(action.user.id).pipe(
            map((transactions) =>
              UserActions.loadAccountBalanceTransactionsSuccess({
                user: action.user,
                transactions,
              }),
            ),
            catchError((error) => [UserActions.userLoadError({ error })]),
          );
        } else {
          return [UserActions.loadAccountBalanceTransactionsFailed({ error: 'User has no ID, cannot continue.' })];
        }
      }),
    ),
  { functional: true },
);

export const loadAccountBalanceTransactionsFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.loadAccountBalanceTransactionsFailed),
      switchMap((action) => {
        const error: string = typeof action.error === 'string' ? action.error : JSON.stringify(action.error);
        return [
          AlertActions.displayAlert({
            title: 'Failed to Load Transactions',
            body: 'We were unable to get your account balance transactions. The following error occurred: ' + error,
            level: 'error',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const loadCashTransactions$ = createEffect(
  (actions$ = inject(Actions), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(UserActions.loadCashTransactions),
      switchMap((action) => {
        if (action.user.id) {
          return userService.fetchCashTransactions(action.user.id).pipe(
            map((transactions) =>
              UserActions.loadCashTransactionsSuccess({
                user: action.user,
                transactions,
              }),
            ),
            catchError((error) => [UserActions.userLoadError({ error })]),
          );
        } else {
          return [UserActions.loadCashTransactionsFailed({ error: 'User has no ID, cannot continue.' })];
        }
      }),
    ),
  { functional: true },
);

export const loadCashTransactionsFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.loadCashTransactionsFailed),
      switchMap((action) => {
        const error: string = typeof action.error === 'string' ? action.error : JSON.stringify(action.error);
        return [
          AlertActions.displayAlert({
            title: 'Failed to Load Transactions',
            body: 'We were unable to get your CurbNTurf Cash transactions. The following error occurred: ' + error,
            level: 'error',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const setInProgressSite$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(UserActions.setInProgressSite),
      exhaustMap((action) => {
        return [UserActions.updateUser({ user: { ...action.user, inProgressId: action.site.id } })];
      }),
    ),
  { functional: true },
);
