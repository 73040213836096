import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { AuthService } from '@curbnturf/auth/src/lib/auth.service';
import { AuthHelpers, ISite, IUser, LogLevel, NGRX_NO_ACTION, Role } from '@curbnturf/entities';
import * as LogActions from '@curbnturf/network/src/lib/log/+state/log.actions';
import * as NetworkActions from '@curbnturf/network/src/lib/network/+state/network.actions';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import * as SiteActions from '@curbnturf/site/src/lib/+state/site.actions';
import { SiteService } from '@curbnturf/site/src/lib/site.service';
import { GlobalSpinnerService } from '@curbnturf/ui/src/lib/spinner/global-spinner.service';
import { GrowService, UserMarketingService, UserService } from '@curbnturf/user';
import * as UserActions from '@curbnturf/user/src/lib/+state/user.actions';
import { UserActionTypes } from '@curbnturf/user/src/lib/+state/user.actions';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, from, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import * as SignUpStateActions from './sign-up-state.actions';
import * as SignUpStateSelectors from './sign-up-state.selectors';

const SPINNER_DEBOUNCE_TIME = 30000;

export const init$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SignUpStateActions.init),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      map(([[action, user], currentUser]) => {
        if (
          currentUser &&
          // this condition needs the admin exception so that it doesn't skip this step.
          (currentUser.role === Role.sales || (currentUser.role?.toLowerCase() as Role) === Role.salesManager || currentUser.role === Role.admin)
        ) {
          return NGRX_NO_ACTION;
        } else if (!user?.id) {
          return SignUpStateActions.restoreUser();
        } else {
          return NGRX_NO_ACTION;
        }
      }),
    ),
  { functional: true },
);

export const nextStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(SignUpStateActions.nextStep),
      withLatestFrom(store.select(SignUpStateSelectors.nextStep)),
      mergeMap(([action, nextStep]) => {
        if (!nextStep || nextStep.route.length < 1) {
          throw new Error('Next Step Not Found');
        }

        return from(router.navigate(nextStep.route)).pipe(
          mergeMap(() => [SignUpStateActions.setCurrentStep({ step: nextStep }), SignUpStateActions.hideSpinner()]),
          catchError(() => [
            AlertActions.displayAlert({
              title: 'Navigation Error',
              body: 'Failed to find the next step in the sign up process. If this problem persists, please contact support.',
            }),
          ]),
        );
      }),
      catchError((err) => {
        return [
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Failed to navigate to the next step in the sign up process.',
              details: err,
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const previousStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(SignUpStateActions.previousStep),
      withLatestFrom(store.select(SignUpStateSelectors.previousStep)),
      mergeMap(([action, previousStep]) => {
        if (!previousStep || previousStep.route.length < 1) {
          throw new Error('Current Step Not Found');
        }

        return from(router.navigate(previousStep.route)).pipe(
          mergeMap(() => [SignUpStateActions.setCurrentStep({ step: previousStep }), SignUpStateActions.hideSpinner()]),
          catchError((err) => {
            return [
              AlertActions.displayAlert({
                title: 'Navigation Error',
                body: 'Failed to find the previous step in the sign up process. If this problem persists, please contact support.',
              }),
              LogActions.logMessage({
                log: {
                  level: LogLevel.ERROR,
                  message: 'Navigation Error',
                  details: err,
                },
              }),
            ];
          }),
        );
      }),
      catchError((err) => {
        return [
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Failed to navigate to the previous step in the sign up process.',
              details: err,
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

// This extracts to actual step object from the step list.
export const setCurrentStepType$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SignUpStateActions.setCurrentStepType),
      // debounce is to prevent the issue caused by defaulting to source and loading current process both setting current step
      debounceTime(0),
      withLatestFrom(store.select(SignUpStateSelectors.getSteps)),
      map(([action, steps]) => {
        const foundStep = steps.steps.find((el) => el.type === action.step);
        if (foundStep) {
          return SignUpStateActions.setCurrentStep({ step: foundStep });
        }
        // Although maybe this should be an error instead
        return NGRX_NO_ACTION;
      }),
    ),
  { functional: true },
);

export const saveState$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    router = inject(Router),
    userService = inject(UserService),
    siteService = inject(SiteService),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.saveState),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([[[action, site], user], status]) => {
        if (status.connected) {
          if (site && user) {
            return combineLatest(siteService.upsert(site), userService.update(user)).pipe(
              mergeMap(([savedSite, savedUser]) => {
                const actions: Action[] = [SignUpStateActions.saveStateSuccess({ user: savedUser, site: savedSite })];

                if (savedUser.inProgressId !== savedSite.id) {
                  actions.push(SignUpStateActions.updateInProgressSiteId());
                }

                if (action.redirect?.route) {
                  return from(router.navigate(action.redirect.route)).pipe(
                    mergeMap(() => [...actions, SignUpStateActions.hideSpinner()]),
                  );
                } else {
                  return [...actions, SignUpStateActions.nextStep({})];
                }
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site or User data is missing.' })];
        } else {
          const actions: Action[] = [];
          if (site) {
            if (!site?.syncId) {
              site = { ...site, syncId: uuidv4() };
            }
            actions.push(NetworkActions.pendingSiteUpdate({ site }));
          }
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
          }

          return [SignUpStateActions.saveStateSuccess({ user, site }), SignUpStateActions.nextStep({}), ...actions];
        }
      }),
    ),
  { functional: true },
);

export const updateInProgressSiteId$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.updateInProgressSiteId),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([[[action, user], site], status]) => {
        if (status.connected) {
          if (user) {
            return userService.update(user).pipe(
              mergeMap((savedUser) => {
                return [
                  {
                    type: UserActionTypes.SetInProgressSite,
                    payload: { user, site },
                  },
                  SignUpStateActions.saveStateSuccess({ user: savedUser }),
                ];
              }),
              catchError((error) => [SignUpStateActions.saveStateFailed({ error })]),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site or User data is missing.' })];
        } else {
          // @todo Do something different because we are offline.
          return [NGRX_NO_ACTION];
        }
      }),
    ),
  { functional: true },
);

export const setSiteTypeHost$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    userService = inject(UserService),
    siteService = inject(SiteService),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.setSiteTypeHost),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[[action, site], user], status], authUser]) => {
        if (status.connected) {
          if (site && user) {
            return siteService.upsert(site).pipe(
              mergeMap((site: ISite) => {
                return userService.update({ ...user, inProgressId: site.id }).pipe(
                  mergeMap((user: IUser) => {
                    const actions: Action[] = [SignUpStateActions.setSiteTypeHostSuccess({ site, user })];
                    if (AuthHelpers.isUserRole(authUser)) {
                      actions.push(UserActions.updateCurrentUserLocally({ user }));
                    }
                    return actions;
                  }),
                );
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site or User data is missing.' })];
        } else {
          const actions: Action[] = [SignUpStateActions.setSiteTypeHostSuccess({ site, user })];
          if (site) {
            if (!site?.syncId) {
              site = { ...site, syncId: uuidv4() };
            }
            actions.push(NetworkActions.pendingSiteUpdate({ site }));
          }
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
            if (AuthHelpers.isUserRole(authUser)) {
              actions.push(UserActions.updateCurrentUserLocally({ user }));
            }
          }
          return actions;
        }
      }),
      catchError((err) => [SignUpStateActions.saveStateFailed({ error: err })]),
    ),
  { functional: true },
);

export const setSiteTypeBoondock$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    userService = inject(UserService),
    siteService = inject(SiteService),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.setSiteTypeBoondock),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[[action, site], user], status], authUser]) => {
        if (status.connected) {
          if (site && user) {
            return siteService.upsert(site).pipe(
              mergeMap((site: ISite) => {
                return userService.update({ ...user, inProgressId: site.id }).pipe(
                  mergeMap((user: IUser) => {
                    const actions: Action[] = [SignUpStateActions.setSiteTypeBoondockSuccess({ site, user })];
                    if (AuthHelpers.isUserRole(authUser)) {
                      actions.push(UserActions.updateCurrentUserLocally({ user }));
                    }
                    return actions;
                  }),
                );
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site or User data is missing.' })];
        } else {
          const actions: Action[] = [SignUpStateActions.setSiteTypeBoondockSuccess({ site, user })];
          if (site) {
            if (!site?.syncId) {
              site = { ...site, syncId: uuidv4() };
            }
            actions.push(NetworkActions.pendingSiteUpdate({ site }));
          }
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
            if (AuthHelpers.isUserRole(authUser)) {
              actions.push(UserActions.updateCurrentUserLocally({ user }));
            }
          }
          return actions;
        }
      }),
      catchError((err) => [SignUpStateActions.saveStateFailed({ error: err })]),
    ),
  { functional: true },
);

export const setSiteTypeHostSuccess$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(
        SignUpStateActions.setSiteTypeHostSuccess,
        SignUpStateActions.setSiteTypeBoondockSuccess,
        SignUpStateActions.startUserTentFlow,
        SignUpStateActions.startUserRvFlow,
      ),
      mergeMap((action) => [SignUpStateActions.nextStep({})]),
    ),
  { functional: true },
);

export const saveRoleSelectStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.startHostFlow, SignUpStateActions.startGuestFlow),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[action, user], status], authUser]) => {
        if (status.connected) {
          if (user) {
            return userService.update(user).pipe(
              mergeMap((savedUser) => {
                const actions: Action[] = [
                  SignUpStateActions.saveStateSuccess({ user: savedUser }),
                  SignUpStateActions.nextStep({}),
                ];
                if (AuthHelpers.isUserRole(authUser)) {
                  actions.push(UserActions.updateCurrentUserLocally({ user }));
                }
                return actions;
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site or User data is missing.' })];
        } else {
          const actions: Action[] = [SignUpStateActions.saveStateSuccess({ user }), SignUpStateActions.nextStep({})];
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
            if (AuthHelpers.isUserRole(authUser)) {
              actions.push(UserActions.updateCurrentUserLocally({ user }));
            }
          }
          return actions;
        }
      }),
    ),
  { functional: true },
);

export const savePhoneStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.savePhoneStep),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[action, user], status], authUser]) => {
        if (status.connected) {
          if (user) {
            return userService.update(user).pipe(
              mergeMap((savedUser) => {
                return userService.postLead(savedUser, action.assistance).pipe(
                  mergeMap(() => {
                    const actions: Action[] = [
                      SignUpStateActions.saveStateSuccess({ user: savedUser }),
                      SignUpStateActions.nextStep({}),
                    ];
                    if (AuthHelpers.isUserRole(authUser)) {
                      actions.push(UserActions.updateCurrentUserLocally({ user }));
                    }
                    return actions;
                  }),
                  catchError((err) => [SignUpStateActions.saveStateFailed({ error: err })]),
                );
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'User data is missing.' })];
        } else {
          const actions: Action[] = [SignUpStateActions.saveStateSuccess({ user }), SignUpStateActions.nextStep({})];
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
            if (AuthHelpers.isUserRole(authUser)) {
              actions.push(UserActions.updateCurrentUserLocally({ user }));
            }
          }
          return actions;
        }
      }),
      catchError((err) => [SignUpStateActions.saveStateFailed({ error: err })]),
    ),
  { functional: true },
);

export const saveUserStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(
        SignUpStateActions.saveSourceStep,
        SignUpStateActions.saveUserRvSizeStep,
        SignUpStateActions.saveUserRoadConditionStep,
      ),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[action, user], status], authUser]) => {
        if (status.connected) {
          if (user) {
            return userService.update(user).pipe(
              mergeMap((savedUser) => {
                const actions: Action[] = [
                  SignUpStateActions.saveStateSuccess({ user: savedUser }),
                  SignUpStateActions.nextStep({}),
                ];
                if (AuthHelpers.isUserRole(authUser)) {
                  actions.push(UserActions.updateCurrentUserLocally({ user: savedUser }));
                }
                return actions;
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'User data is missing.' })];
        } else {
          const actions: Action[] = [SignUpStateActions.saveStateSuccess({ user }), SignUpStateActions.nextStep({})];
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
            if (AuthHelpers.isUserRole(authUser)) {
              actions.push(UserActions.updateCurrentUserLocally({ user }));
            }
          }
          return actions;
        }
      }),
    ),
  { functional: true },
);

export const saveSiteStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(
        SignUpStateActions.savePropertyTypeStep,
        SignUpStateActions.saveAmenitiesStep,
        SignUpStateActions.saveActivitiesStep,
        SignUpStateActions.saveIsolationStep,
        SignUpStateActions.saveBoondockLandStep,
        SignUpStateActions.saveVehiclesStep,
        SignUpStateActions.saveDetailsStep,
        SignUpStateActions.savePriceStep,
        SignUpStateActions.saveBookingStep,
        SignUpStateActions.savePoliciesStep,
        SignUpStateActions.saveLocationStep,
        SignUpStateActions.saveDescriptionStep,
        SignUpStateActions.savePhotosStep,
      ),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([[action, site], status]) => {
        if (status.connected) {
          if (site) {
            return siteService
              .upsert(site)
              .pipe(
                mergeMap((savedSite: ISite) => [
                  SignUpStateActions.saveStateSuccess({ site: savedSite }),
                  SignUpStateActions.nextStep({}),
                ]),
              );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site data is missing.' })];
        } else {
          if (site) {
            if (!site?.syncId) {
              site = { ...site, syncId: uuidv4() };
            }
            const actions: Action[] = [
              SignUpStateActions.saveStateSuccess({ site }),
              SignUpStateActions.nextStep({}),
              NetworkActions.pendingSiteUpdate({ site }),
            ];
            return actions;
          }
          return [SignUpStateActions.saveStateFailed({ error: 'Site data is missing.' })];
        }
      }),
    ),
  { functional: true },
);

export const saveUserProfilePhotoStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SignUpStateActions.updateProfilePhoto),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[action, user], authUser]) => {
        if (user) {
          if (AuthHelpers.isUserRole(authUser)) {
            return [UserActions.updateCurrentUserLocally({ user })];
          }
          return [NGRX_NO_ACTION];
        }
        return [SignUpStateActions.saveStateFailed({ error: 'User data is missing.' })];
      }),
    ),
  { functional: true },
);

export const completeSalesPublishStep$ = createEffect(
  (actions$ = inject(Actions), growService = inject(GrowService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.siteSalesComplete),
      mergeMap((action) => {
        return growService.notifyClientListingReady(action.siteId).pipe(
          map(() =>
            AlertActions.displayAlert({
              title: 'Client Notified',
              body: 'Your client has been notified and now needs to publish the listing. You should follow up with the client if they have not published their site shortly',
              level: 'warning',
            }),
          ),
          catchError((err) => {
            return [
              LogActions.logMessage({
                log: {
                  level: LogLevel.ERROR,
                  message: 'Error when notifying user of completed listing.',
                  details: err,
                  subjectType: 'site',
                  subjectId: action.siteId,
                },
              }),
              AlertActions.displayAlert({
                title: 'Error Notifying Client',
                body: 'We attempted to notify your client, but an error has occurred. You may need to follow up with the client.',
                level: 'error',
              }),
            ];
          }),
        );
      }),
    ),
  { functional: true },
);

export const siteImagesUpdated$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.siteImagesUpdated),
      mergeMap((action) => {
        return [SignUpStateActions.updateSitePhotos({ photos: action.photos, siteId: action.id })];
      }),
    ),
  { functional: true },
);

export const updateSitePhotos$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.updateSitePhotos),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([[action, site], status]) => {
        if (status.connected) {
          if ((site && !action.siteId) || (site && action.siteId && action.siteId === site.id)) {
            return siteService.update(site).pipe(map(() => NGRX_NO_ACTION));
          }
          return [NGRX_NO_ACTION];
        } else {
          const actions: Action[] = [NGRX_NO_ACTION];
          if (site) {
            if (!site?.syncId) {
              site = { ...site, syncId: uuidv4() };
            }
            actions.push(NetworkActions.pendingSiteUpdate({ site }));
          }
          return actions;
        }
      }),
    ),
  { functional: true },
);

export const saveUserRvStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.saveUserRvStep, SignUpStateActions.saveUserTentStep),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[action, user], status], authUser]) => {
        if (user) {
          if (status.connected) {
            return userService.update(user).pipe(
              mergeMap((savedUser) => {
                if (savedUser.rvs && savedUser.rvs.length > 0 && !savedUser.selectedRv) {
                  const selectedRv = savedUser.rvs[0];
                  const selectRvUser = { ...savedUser, selectedRv: { ...selectedRv } };
                  // Second user update to set the default RV
                  return userService.update(selectRvUser).pipe(
                    mergeMap((secondSavedUser) => {
                      const actions: Action[] = [
                        SignUpStateActions.saveStateSuccess({ user: secondSavedUser }),
                        SignUpStateActions.nextStep({}),
                      ];
                      if (AuthHelpers.isUserRole(authUser)) {
                        actions.push(UserActions.updateCurrentUserLocally({ user: secondSavedUser }));
                      }
                      return actions;
                    }),
                  );
                } else {
                  const actions: Action[] = [
                    SignUpStateActions.saveStateSuccess({ user: savedUser }),
                    SignUpStateActions.nextStep({}),
                  ];
                  if (AuthHelpers.isUserRole(authUser)) {
                    actions.push(UserActions.updateCurrentUserLocally({ user: savedUser }));
                  }
                  return actions;
                }
              }),
            );
          } else {
            // @todo do something else because we are offline.
            console.error('We are offline');
          }
        }
        return [SignUpStateActions.saveStateFailed({ error: 'User data is missing.' })];
      }),
    ),
  { functional: true },
);

export const saveClientProfileStep$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    userService = inject(UserService),
    growService = inject(GrowService),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.saveClientProfileStep),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[action, status], currentUser]) => {
        const user = action.user;
        if (status.connected) {
          if (user && user.email) {
            return from(growService.register(user)).pipe(
              mergeMap((savedUser) =>
                userService.postLead(savedUser, false).pipe(
                  mergeMap(() => {
                    const actions: Action[] = [];
                    if (action.newsletter) {
                      actions.push(SignUpStateActions.registerForNewsletter({ user: savedUser }));
                    }
                    return [
                      SignUpStateActions.sendSalesClientEmail({ user: savedUser }),
                      SignUpStateActions.saveClientProfileStepSuccess({ user: savedUser }),
                      SignUpStateActions.startNewSite({ siteType: 'standard', user: savedUser, currentUser }),
                      ...actions,
                    ];
                  }),
                  catchError((err) => [SignUpStateActions.saveClientProfileStepFailed({ error: err })]),
                )),
              catchError((err) => [SignUpStateActions.saveClientProfileStepFailed({ error: err })]),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'User data is missing.' })];
        }
        return [NGRX_NO_ACTION];
        // Maybe nothing different if we are offline?
      }),
      catchError((err) => [SignUpStateActions.saveStateFailed({ error: err })]),
    ),
  { functional: true },
);

export const saveClientProfileStepFailed$ = createEffect(
  (
    actions$ = inject(Actions),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.saveClientProfileStepFailed),
      mergeMap((action: any) => {
        const error = action.error;
        return [
          AlertActions.displayAlert({
            title: 'An Error Saving the Client Profile',
            body: 'An error occurred while attempting to save the client profile: ' + error,
            level: 'error',
          })
        ];
      }
    ),
  ),
  { functional: true },
);

export const registerForNewsletter$ = createEffect(
  (
    actions$ = inject(Actions),
    userMarketingService = inject(UserMarketingService)
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.registerForNewsletter),
      mergeMap((action) => {
        return userMarketingService.registerForNewsletter(action.user).pipe(
          mergeMap(() => [NGRX_NO_ACTION]),
        );
      }),
  ),
  { functional: true },
);

export const uploadUserImage$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    userService = inject(UserService),
    spinnerService = inject(GlobalSpinnerService),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.uploadUserProfileImage),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      switchMap(([[[action, authUser], user], status]) => {
        const files = action.formData;
        if (!files) {
          return of(NGRX_NO_ACTION);
        }

        if (!user || !user.id) {
          return [
            UserActions.userImageUploadError({
              error: 'User missing id',
            }),
          ];
        }

        spinnerService.spinnerOn();

        let id = user.id;

        if (user && user.id && action?.data?.id && user.id === action.data.id) {
          if (AuthHelpers.isAdminRole(authUser) || AuthHelpers.hasSalesOwnership(authUser, user)) {
            id = action.data.id;
          }
        }

        if (status.connected) {
          return userService.uploadImage(id, files).pipe(
            switchMap((updatedUser: IUser) => {
              spinnerService.spinnerOff();

              return [
                UserActions.userImageUploaded({
                  ...updatedUser,
                }),
              ];
            }),
            catchError((error) => {
              spinnerService.spinnerOff();

              return [
                UserActions.userImageUploadError({
                  error,
                }),
              ];
            }),
          );
        } else {
          return [NetworkActions.deferUserImageUpload({ photo: files })];
        }
      }),
      catchError((error) => {
        spinnerService.spinnerOff();

        return [UserActions.userImageUploadError({ error })];
      }),
    ),
  { functional: true },
);

export const saveStateFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SignUpStateActions.saveStateFailed),
      mergeMap((action) => {
        return [
          SignUpStateActions.hideSpinner(),
          AlertActions.displayAlert({
            title: 'Error Saving Your Site or User',
            body: 'If you continue to encounter this error, please contact support.',
            level: 'error',
          }),
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Error Saving Your Site or User',
              details: action.error,
            },
          }),
        ];
      }),
    ),
  { functional: true },
);

export const restoreSite$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.restoreSite),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([[[[action, stateSite], stateUser], currentUser], status]) => {
        if (status.connected) {
          if (!stateSite?.id || (action.siteId && stateSite.id !== action.siteId)) {
            const siteId = stateUser?.inProgressId ?? currentUser.inProgressId;
            if (siteId) {
              return siteService.fetch(siteId).pipe(
                map((site) => SignUpStateActions.restoreSiteSuccess({ site })),
                catchError((err) => [SignUpStateActions.restoreSiteFailed({ error: err })]),
              );
            }
          } else if (action.siteId && stateSite.id === action.siteId) {
            // The site already exists in the state, just use it.
            return [SignUpStateActions.restoreSiteSuccess({ site: stateSite })];
          }
        }
        return [NGRX_NO_ACTION];
      }),
    ),
  { functional: true },
);

export const restoreSiteSuccess$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(SignUpStateActions.restoreSiteSuccess),
      withLatestFrom(store.select(SignUpStateSelectors.getCurrentStep)),
      mergeMap(([action, currentStep]) => {
        if (currentStep) {
          return from(router.navigate(currentStep.route)).pipe(map(() => NGRX_NO_ACTION));
        } else {
          return [NGRX_NO_ACTION];
        }
      }),
    ),
  { functional: true },
);

export const restoreUser$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), authService = inject(AuthService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.restoreUser),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[action, stateUser], currentUser]) => {
        if (!stateUser?.id) {
          if (currentUser.id) {
            return [SignUpStateActions.restoreUserSuccess({ user: currentUser })];
          } else {
            console.warn("We hit the spot that we shouldn't have hit!");
            // Hopefully we won't need to do this, really ever.
            return authService.getCurrentUser().pipe(
              map((user) => SignUpStateActions.restoreUserSuccess({ user })),
              catchError((err) => [SignUpStateActions.restoreUserFailed({ error: err })]),
            );
          }
        }
        return [NGRX_NO_ACTION];
      }),
    ),
  { functional: true },
);

export const restoreUserSuccess$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(SignUpStateActions.restoreUserSuccess),
      withLatestFrom(store.select(SignUpStateSelectors.getUser)),
      withLatestFrom(store.select(SignUpStateSelectors.getCurrentStep)),
      mergeMap(([[action, user], currentStep]) => {
        if (
          user?.inProgressId &&
          user?.role !== Role.guest &&
          (user?.role?.toLowerCase() as Role) !== Role.salesManager &&
          user?.role !== Role.sales
        ) {
          return [SignUpStateActions.restoreSite({ siteId: user.inProgressId })];
        } else {
          if (currentStep) {
            return from(router.navigate(currentStep.route)).pipe(map(() => NGRX_NO_ACTION));
          } else {
            return [NGRX_NO_ACTION];
          }
        }
      }),
    ),
  { functional: true },
);

// This effect hides the spinner after 30 seconds if the spinner is still active
export const displaySpinner$ = createEffect(
  (actions$ = inject(Actions), platformId = inject(PLATFORM_ID)) =>
    actions$.pipe(
      ofType(SignUpStateActions.displaySpinner),
      debounceTime(isPlatformServer(platformId) ? 0 : SPINNER_DEBOUNCE_TIME),
      map(() => SignUpStateActions.hideSpinner()),
    ),
  { functional: true },
);

export const publishSite$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SignUpStateActions.publishSite),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      mergeMap(([action, site]) => {
        return site ? [SignUpStateActions.displaySpinner(), SiteActions.enableSite({ site })] : [NGRX_NO_ACTION];
      }),
      catchError((err) => {
        return [
          AlertActions.displayAlert({
            title: 'Failed to Publish Site',
            body: 'An error occurred and we were unable to publish your site.',
            level: 'error',
          }),
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Publish Site Failure: ' + err.message,
              details: JSON.stringify(err),
            },
          }),
          SignUpStateActions.publishSiteFailed(err),
        ];
      }),
    ),
  { functional: true },
);

export const publishLater$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SignUpStateActions.publishLater),
      withLatestFrom(store.select(SignUpStateSelectors.getSite)),
      mergeMap(([action, site]) => {
        return site ? [SignUpStateActions.displaySpinner(), SiteActions.enableAndHideSite({ site })] : [NGRX_NO_ACTION];
      }),
      catchError((err) => {
        return [
          AlertActions.displayAlert({
            title: 'Failed to Update Site',
            body: 'An error occurred and we were unable to update your site status.',
            level: 'error',
          }),
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Publish Later Failure: ' + err.message,
              details: JSON.stringify(err),
            },
          }),
          SignUpStateActions.publishLaterFailed(err),
        ];
      }),
    ),
  { functional: true },
);

export const clearInProgressSite$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), userService = inject(UserService)) =>
    actions$.pipe(
      ofType(SignUpStateActions.clearInProgressSite),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[[action, user], status], authUser]) => {
        if (status.connected) {
          if (user) {
            const updatedUser = { ...user, inProgressId: undefined };
            return userService.update(updatedUser).pipe(
              mergeMap(() => {
                const actions: Action[] = [SignUpStateActions.clearInProgressSiteSuccess()];
                if (AuthHelpers.isUserRole(authUser)) {
                  actions.push(UserActions.updateCurrentUserLocally({ user: updatedUser }));
                }
                return actions;
              }),
            );
          }
          return [SignUpStateActions.saveStateFailed({ error: 'User data is missing.' })];
        } else {
          const actions: Action[] = [SignUpStateActions.clearInProgressSiteSuccess()];
          if (user) {
            actions.push(NetworkActions.deferUserUpdate({ user }));
          }
          return actions;
        }
      }),
    ),
  { functional: true },
);

export const startNewSite$ = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    userService = inject(UserService),
    siteService = inject(SiteService),
  ) =>
    actions$.pipe(
      ofType(SignUpStateActions.startNewSite),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      mergeMap(([[action, status], currentUser]) => {
        const site = {
          type: action.siteType,
        };
        if (site && (action.user || currentUser)) {
          let user: IUser;
          if (currentUser !== action.user && action.user) {
            user = action.user;
          } else {
            user = currentUser;
          }
          if (status.connected) {
            return siteService.create(site).pipe(
              mergeMap((siteResult) => {
                const userToSave = { ...user, inProgressId: siteResult.id };
                return userService.update(userToSave).pipe(
                  mergeMap((userResult) => {
                    const actions: Action[] = [
                      SignUpStateActions.startNewSiteSuccess({ site: siteResult, user: userResult }),
                    ];
                    if (AuthHelpers.isUserRole(currentUser)) {
                      actions.push(UserActions.updateCurrentUserLocally({ user: userResult }));
                    }
                    return actions;
                  }),
                );
              }),
            );
          } else {
            return [SignUpStateActions.startNewSiteSuccess({ site, user })];
          }
        } else {
          return [NGRX_NO_ACTION];
        }
      }),
      catchError((err) => {
        return [
          LogActions.logMessage({
            log: {
              level: LogLevel.ERROR,
              message: 'Failed to start new listing.',
              details: err,
            },
          }),
          SignUpStateActions.startNewSiteFailed({ error: err }),
        ];
      }),
    ),
  { functional: true },
);

export const startNewSiteSuccess$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store), router = inject(Router)) =>
    actions$.pipe(
      ofType(SignUpStateActions.startNewSiteSuccess),
      withLatestFrom(store.select(SignUpStateSelectors.getCurrentStep)),
      mergeMap(([action, currentStep]) => {
        let route = ['', 'sign-up'];
        if (currentStep) {
          route = currentStep.route;
        }
        return from(router.navigate(route)).pipe(map(() => NGRX_NO_ACTION));
      }),
    ),
  { functional: true },
);

export const setInProgressSite$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SignUpStateActions.setInProgressSite),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      map(([action, user]) => UserActions.setInProgressSite({ site: action.site, user })),
    ),
  { functional: true },
);
