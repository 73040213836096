import {
  FlowStepType,
  FlowType,
  GUEST_FLOW_RV,
  GUEST_FLOW_TENT,
  HelperFunctions,
  HOST_BOONDOCK_FLOW,
  HOST_SITE_FLOW,
  IPolicy,
  IRV,
  ISignUpFlowStep,
  ISite,
  IUser,
  ListingType,
  Role,
  SALES_FLOW,
} from '@curbnturf/entities';
import { toPrecision } from '@curbnturf/helpers';
import { Action, createReducer, on } from '@ngrx/store';
import * as SignUpStateActions from './sign-up-state.actions';

const { findLatestStep } = HelperFunctions;

export interface SignUpStateState {
  currentStep?: ISignUpFlowStep;
  percentComplete?: number;
  redirect?: string;
  user?: IUser;
  site?: ISite;
  spinner: boolean;
  steps: ISignUpFlowStep[];
}

export const initialState: SignUpStateState = {
  spinner: false,
  steps: [],
  user: {},
  site: {},
};

const signUpStateReducer = createReducer(
  initialState,
  on(SignUpStateActions.setState, (state, action) => {
    return {
      ...state,
      ...{ ...action, type: undefined }, // don't add the action type to the state.
      percentComplete: percentCompleteCalculation(action.steps, action.currentStep),
      redirect: undefined,
    };
  }),
  on(SignUpStateActions.redirect, (state, action) => {
    return {
      ...state,
      redirect: action.routeTo,
    };
  }),
  on(SignUpStateActions.startHostFlow, (state, action) => {
    const steps = [...HOST_SITE_FLOW];
    const currentStep = HOST_SITE_FLOW.slice(0, 1).shift();
    return {
      ...state,
      steps: [...HOST_SITE_FLOW],
      currentStep: HOST_SITE_FLOW.slice(0, 1).shift(),
      percentComplete: percentCompleteCalculation(steps, currentStep),
      user: { ...state.user, ...action.user, role: Role.host },
    };
  }),
  on(SignUpStateActions.startGuestFlow, (state, action) => {
    const steps = [...GUEST_FLOW_RV];
    const currentStep = GUEST_FLOW_RV.slice(0, 1).shift();
    return {
      ...state,
      steps,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
      user: { ...state.user, ...action.user, role: Role.guest },
    };
  }),
  on(SignUpStateActions.saveState, (state) => {
    return {
      ...state,
      spinner: true,
    };
  }),
  on(SignUpStateActions.updateInProgressSiteId, (state) => {
    const user = {
      ...state.user,
      inProgressId: state.site?.id,
    };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.saveStateSuccess, (state, action) => {
    const user = { ...state.user, ...action.user };
    const site = { ...state.site, ...action.site };

    if (user.id) {
      site.user = { ...site.user, id: user.id };
    }

    if (action.site && action.site.id) {
      user.inProgressId = action.site.id;
    }

    if (action.user && !action.user.selectedRv && action?.user?.rvs && action.user.rvs.length > 0) {
      const selectedRv = action.user.rvs.slice().shift();
      if (selectedRv) {
        user.selectedRv = selectedRv;
      }
    }

    return {
      ...state,
      user,
      site,
      spinner: false,
    };
  }),
  on(SignUpStateActions.saveStateFailed, (state) => {
    return {
      ...state,
      spinner: false,
    };
  }),
  on(SignUpStateActions.setCurrentStep, (state, action) => {
    return {
      ...state,
      currentStep: action.step,
      percentComplete: percentCompleteCalculation(state.steps, action.step),
    };
  }),
  on(SignUpStateActions.setSiteTypeHost, (state, action) => {
    const steps = [...HOST_SITE_FLOW];
    const currentStep = steps.find((el) => el.type === FlowStepType.RoleSelector);

    return {
      ...state,
      steps,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
      user: { ...state.user, role: Role.host },
      site: { ...state.site, ...action.site, type: ListingType.standard },
    };
  }),
  on(SignUpStateActions.setSiteTypeHostSuccess, (state, action) => {
    return {
      ...state,
      user: { ...action.user, inProgressId: action.site?.id },
      site: { ...action.site },
    };
  }),
  on(SignUpStateActions.setSiteTypeBoondock, (state, action) => {
    const steps = [...HOST_BOONDOCK_FLOW];
    const currentStep = steps.find((el) => el.type === FlowStepType.RoleSelector);

    return {
      ...state,
      steps,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
      user: { ...state.user, role: Role.boondocker },
      site: { ...state.site, ...action.site, type: ListingType.boondock },
    };
  }),
  on(SignUpStateActions.setSiteTypeBoondockSuccess, (state, action) => {
    return {
      ...state,
      user: { ...action.user, inProgressId: action.site?.id },
      site: { ...action.site },
    };
  }),
  on(SignUpStateActions.saveSourceStep, (state, action) => {
    const user = { ...state.user, source: action.user.source };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.savePhoneStep, (state, action) => {
    const user = { ...state.user, phone: action.user.phone };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.savePropertyTypeStep, (state, action) => {
    const site = { ...state.site, landTypes: action.site.landTypes };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveAmenitiesStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      amenities: action.site.amenities,
      signalReception: action.site.signalReception,
      extras: action.site.extras,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveActivitiesStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      activities: action.site.activities,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveIsolationStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      noise: action.site.noise,
      privacy: action.site.privacy,
      accessRoutes: action.site.accessRoutes,
      potentialHazards: action.site.potentialHazards,
      potentialHazardDescription: action.site.potentialHazardDescription,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveBoondockLandStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      amenities: [...(state.site?.amenities || []), ...(action.site?.amenities || [])],
      sizeCars: action.site?.sizeCars,
      sizeBuses: action.site?.sizeBuses,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveVehiclesStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      vehicles: action.site.vehicles,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveDetailsStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      amenities: [...(state.site?.amenities || []), ...(action.site.amenities || [])],
      maxWidth: action.site.maxWidth,
      maxLength: action.site.maxLength,
      maxHeight: action.site.maxHeight,
      maxGuests: action.site.maxGuests,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.savePriceStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      price: action.site.price,
      cancellationPolicy: action.site.cancellationPolicy,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveBookingStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      checkIn: action.site.checkIn,
      checkOut: action.site.checkOut,
      minLengthOfStayInDays: action.site.minLengthOfStayInDays,
      maxLengthOfStayInDays: action.site.maxLengthOfStayInDays,
      reserveDaysInAdvance: action.site.reserveDaysInAdvance,
      bookingWindow: action.site.bookingWindow,
      checkInProcess: action.site.checkInProcess,
      reservationProcess: action.site.reservationProcess,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.savePoliciesStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      policies: action.site.policies?.map((el: IPolicy) => ({ ...el, siteId: state.site?.id })),
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.saveLocationStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      address: action.site.address,
      coordinates: action.site.coordinates,
      landOwner: action.site.landOwner,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.startUserRvFlow, (state) => {
    const steps = [...GUEST_FLOW_RV];
    const currentStep = steps.find((el) => el.type === FlowStepType.UserGarage);
    return {
      ...state,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
      steps,
    };
  }),
  on(SignUpStateActions.startUserTentFlow, (state) => {
    const steps = [...GUEST_FLOW_TENT];
    const currentStep = steps.find((el) => el.type === FlowStepType.UserGarage);
    return {
      ...state,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
      steps,
    };
  }),
  on(SignUpStateActions.saveUserRvStep, (state, action) => {
    let rvs: IRV[] = state.user?.rvs?.slice() || [];

    // Check for a selected RV first - in case we are repeating this step.
    let updatedRv: IRV;
    let selectedRv: IRV | undefined;
    if (state.user?.selectedRv?.id) {
      rvs = state.user?.rvs?.filter((el) => el.id !== action.rv?.id) || [];
      updatedRv = { ...state.user.selectedRv, ...action.rv };
      selectedRv = { ...state.user.selectedRv, ...action.rv };
    } else {
      updatedRv = action.rv;
      selectedRv = undefined;
    }

    const user = {
      ...state.user,
      rvs: [...rvs, updatedRv],
      selectedRv,
    };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.saveUserTentStep, (state, action) => {
    let rvs: IRV[] = state.user?.rvs?.slice() || [];
    const foundRv = rvs.find((el) => el.id === action.rv?.id);

    // Check for a selected RV first - in case we are repeating this step.
    let selectedRv: IRV | undefined;
    rvs = state.user?.rvs?.filter((el) => el.id !== action.rv?.id) || [];
    if (state.user?.selectedRv?.id) {
      rvs.push({ ...foundRv, ...action.rv });
      selectedRv = { ...state.user.selectedRv, ...action.rv };
    } else {
      rvs.push({ ...foundRv, ...action.rv });
      selectedRv = undefined;
    }

    const user = {
      ...state.user,
      rvs,
      selectedRv,
    };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.saveUserRvSizeStep, (state, action) => {
    let rvs: IRV[] = state.user?.rvs?.slice() || [];

    // Check for a selected RV first - in case we are repeating this step.
    let selectedRv: IRV | undefined;
    let updatedRv: IRV;
    if (state.user?.selectedRv?.id) {
      updatedRv = { ...state.user.selectedRv, ...action.rv };
      rvs = state.user?.rvs?.filter((el) => el.id !== updatedRv.id) || [];
      rvs.push(updatedRv);
      selectedRv = updatedRv;
    } else {
      rvs.push({ ...action.rv });
      selectedRv = undefined;
    }

    const user = {
      ...state.user,
      rvs,
      selectedRv,
    };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.saveUserRoadConditionStep, (state, action) => {
    let rvs: IRV[] = state.user?.rvs?.slice() || [];

    // Check for a selected RV first - in case we are repeating this step.
    let selectedRv: IRV | undefined;
    let updatedRv: IRV;
    if (state.user?.selectedRv?.id) {
      updatedRv = { ...state.user.selectedRv, ...action.rv };
      rvs = state.user?.rvs?.filter((el) => el.id !== updatedRv.id) || [];
      rvs.push(updatedRv);
      selectedRv = updatedRv;
    } else {
      rvs.push({ ...action.rv });
      selectedRv = undefined;
    }

    const user = {
      ...state.user,
      rvs,
      selectedRv,
    };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.saveDescriptionStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      name: action.site.name,
      description: action.site.description,
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.updateProfilePhoto, (state, action) => {
    const user: IUser = {
      ...state.user,
      photo: action.photo,
    };

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.updateSitePhotos, (state, action) => {
    const site: ISite = {
      ...state.site,
      photos: [...action.photos],
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.savePhotosStep, (state, action) => {
    const site: ISite = {
      ...state.site,
      photos: [...(action.site?.photos || [])],
    };

    return {
      ...state,
      site,
    };
  }),
  on(SignUpStateActions.restoreUserSuccess, (state, action) => {
    const currentUser = action.currentUser;
    const steps = [];
    let currentStep: ISignUpFlowStep | undefined;
    let flow: FlowType;
    if (action.user.role === Role.guest) {
      if (action.user.selectedRv) {
        if (action.user.selectedRv.tent) {
          steps.push(...GUEST_FLOW_TENT);
          currentStep = GUEST_FLOW_TENT.slice(0, 1).shift();
        } else {
          steps.push(...GUEST_FLOW_RV);
          currentStep = GUEST_FLOW_RV.slice(0, 1).shift();
        }
      } else {
        steps.push(...GUEST_FLOW_RV);
        currentStep = GUEST_FLOW_RV.slice(0, 1).shift();
      }
      flow = FlowType.guest;
    } else if (action.user.role === Role.boondocker) {
      steps.push(...HOST_BOONDOCK_FLOW);
      currentStep = HOST_BOONDOCK_FLOW.slice(0, 1).shift();
      flow = FlowType.boondock;
    } else if (
      currentUser &&
      (currentUser.role === Role.sales || (currentUser.role?.toLowerCase() as Role) === Role.salesManager)
    ) {
      // defaulting to host
      steps.push(...SALES_FLOW);
      currentStep = SALES_FLOW.slice(0, 1).shift();
      flow = FlowType.sales;
    } else {
      // defaulting to host
      steps.push(...HOST_SITE_FLOW);
      currentStep = HOST_SITE_FLOW.slice(0, 1).shift();
      flow = FlowType.host;
    }

    // Figure out what the current step should be
    const latestStep = findLatestStep(flow, state.site, action.user);
    if (latestStep) {
      const foundStep = steps.find((el) => el.type === latestStep);
      if (foundStep) {
        currentStep = foundStep;
      }
    }

    if (!currentStep) {
      currentStep = state.currentStep;
    }

    return {
      ...state,
      user: { ...state.user, ...action.user },
      steps,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
    };
  }),
  on(SignUpStateActions.restoreSiteSuccess, (state, action) => {
    const currentUser = action.currentUser;
    const site = action.site;
    const flow: FlowType = site.type === ListingType.boondock ? FlowType.boondock : FlowType.host;
    const steps = [];
    let currentStep: ISignUpFlowStep | undefined = undefined;
    if (
      currentUser &&
      (currentUser.role === Role.sales || (currentUser.role?.toLowerCase() as Role) === Role.salesManager)
    ) {
      steps.push(...SALES_FLOW);
      currentStep = SALES_FLOW.slice(0, 1).shift();
    } else if (site.type === ListingType.standard) {
      steps.push(...HOST_SITE_FLOW);
      currentStep = HOST_SITE_FLOW.slice(0, 1).shift();
    } else if (site.type === ListingType.boondock) {
      steps.push(...HOST_BOONDOCK_FLOW);
      currentStep = HOST_BOONDOCK_FLOW.slice(0, 1).shift();
    }

    // Figure out what the current step should be
    const latestStep = findLatestStep(flow, site, state.user);
    if (latestStep) {
      const foundStep = steps.find((el) => el.type === latestStep);
      if (foundStep) {
        currentStep = foundStep;
      }
    }

    if (!currentStep) {
      currentStep = state.currentStep;
    }

    return {
      ...state,
      site: { ...state.site, ...site },
      steps,
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
    };
  }),
  on(SignUpStateActions.displaySpinner, (state) => {
    return {
      ...state,
      spinner: true,
    };
  }),
  on(SignUpStateActions.hideSpinner, (state) => {
    return {
      ...state,
      spinner: false,
    };
  }),
  on(SignUpStateActions.resetProcess, () => {
    return {
      currentStep: undefined,
      percentComplete: 0,
      redirect: undefined,
      user: {},
      site: {},
      spinner: false,
      steps: [],
    };
  }),
  on(SignUpStateActions.setInProgressSite, (state, action) => {
    let user = state.user;
    if (action.site?.id) {
      user = { ...state.user, inProgressId: action.site.id };
    }

    return {
      ...state,
      user,
    };
  }),
  on(SignUpStateActions.startNewSite, (state, action) => {
    const currentUser = action.currentUser;

    const site: ISite = {
      type: action.siteType,
    };

    let role;
    let steps = [];
    let currentStep;
    if (site.type === 'boondock') {
      role = Role.boondocker;
      steps.push(...HOST_BOONDOCK_FLOW);
    } else {
      role = Role.host;
      steps.push(...HOST_SITE_FLOW);
    }

    const user: IUser = {
      ...state.user,
      ...action.user,
      role,
    };

    const flow: FlowType = site.type === ListingType.boondock ? FlowType.boondock : FlowType.host;
    let latestStep: FlowStepType | undefined;
    if (
      currentUser &&
      (currentUser.role === Role.sales || (currentUser.role?.toLowerCase() as Role) === Role.salesManager)
    ) {
      latestStep = findLatestStep(FlowType.sales, site, user);
    } else {
      latestStep = findLatestStep(flow, site, user);
    }
    if (latestStep) {
      const foundStep = steps.find((el) => el.type === latestStep);
      if (foundStep) {
        currentStep = foundStep;
      }
    }

    return {
      currentStep,
      percentComplete: percentCompleteCalculation(steps, currentStep),
      redirect: undefined,
      user,
      site,
      spinner: false,
      steps,
    };
  }),
  on(SignUpStateActions.startNewSiteSuccess, (state, action) => {
    const user = { ...state.user, ...action.user };
    const site = { ...state.site, ...action.site };

    if (user.id) {
      site.user = user;
    }

    return {
      ...state,
      user,
      site,
      spinner: false,
    };
  }),
  on(SignUpStateActions.saveClientProfileStepSuccess, (state, action) => {
    const user = action.user;
    return {
      ...state,
      user,
      site: {
        user,
      },
    };
  }),
);

export function reducer(state: SignUpStateState | undefined, action: Action) {
  return signUpStateReducer(state, action);
}

function percentCompleteCalculation(steps: ISignUpFlowStep[], currentStep?: ISignUpFlowStep): number {
  if (!steps || !currentStep) {
    return 0;
  }

  const foundIndex = steps.findIndex((el) => el.type === currentStep?.type);
  return foundIndex ? toPrecision((foundIndex / (steps.length - 1)) * 100, 0) : 0;
}
