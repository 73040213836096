import { ISignUpFlowStep, NGRX_BUCKETS } from '@curbnturf/entities';
import { toPrecision } from '@curbnturf/helpers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignUpStateState } from './sign-up-state.reducer';

// Lookup the 'SignUpState' feature state managed by NgRx
export const getSignUpStateState = createFeatureSelector<SignUpStateState>(NGRX_BUCKETS.signUpState);

export const getSpinner = createSelector(getSignUpStateState, (state: SignUpStateState) => state.spinner);
export const getBoondocking = createSelector(
  getSignUpStateState,
  (state: SignUpStateState) => state.site?.type === 'boondock',
);
export const getGuest = createSelector(getSignUpStateState, (state: SignUpStateState) => state.user?.role === 'guest');

export const getRedirect = createSelector(getSignUpStateState, (state: SignUpStateState) => state.redirect);

export const getUser = createSelector(getSignUpStateState, (state: SignUpStateState) => state.user);

export const getSite = createSelector(getSignUpStateState, (state: SignUpStateState) => state.site);

export const getRv = createSelector(getSignUpStateState, (state: SignUpStateState) => state.user?.selectedRv);

export const isLastStep = createSelector(getSignUpStateState, (state: SignUpStateState) => {
  if (state.currentStep) {
    const foundStepIndex = state.steps.findIndex((el) => el === state.currentStep);
    if (foundStepIndex !== -1) {
      if (state.steps.length - 1 === foundStepIndex) {
        return true;
      }
    }
  }
  return false;
});

export const isFirstStep = createSelector(getSignUpStateState, (state: SignUpStateState) => {
  return state.currentStep ? state.steps.findIndex((el) => el === state.currentStep) === 0 : true;
});

export const previousStep = createSelector(getSignUpStateState, (state: SignUpStateState) => {
  if (state.currentStep) {
    const foundIndex = state.steps.findIndex((el) => el === state.currentStep);
    if (foundIndex >= 1) {
      return state.steps[foundIndex - 1];
    }
  }
  return undefined;
});

export const nextStep = createSelector(getSignUpStateState, (state: SignUpStateState) => {
  if (state.currentStep) {
    const foundIndex = state.steps.findIndex((el) => el === state.currentStep);
    if (foundIndex >= 0 && foundIndex + 1 !== state.steps.length) {
      return state.steps[foundIndex + 1];
    }
  }
  return undefined;
});

export const getCurrentStep = createSelector(getSignUpStateState, (state: SignUpStateState) => state.currentStep);

export const getSteps = createSelector(
  getSignUpStateState,
  isLastStep,
  isFirstStep,
  previousStep,
  nextStep,
  (
    state: SignUpStateState,
    isLastStep: boolean,
    isFirstStep: boolean,
    previousStep?: ISignUpFlowStep,
    nextStep?: ISignUpFlowStep,
  ) => ({
    steps: state.steps,
    currentStep: state.currentStep,
    previousStep,
    nextStep,
    isLastStep,
    isFirstStep,
  }),
);

export const getPercentComplete = createSelector(getSignUpStateState, (state: SignUpStateState) => {
  return state.percentComplete || 0
});
