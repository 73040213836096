import { AsyncPipe, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { AuthModalFacade } from '@curbnturf/auth-modal/src/lib/+state/auth-modal.facade';
import { AuthModalsComponent } from '@curbnturf/auth-modal/src/lib/auth-modals/auth-modals.component';
import { StatusFacade } from '@curbnturf/status';
import { IonicModule } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'curbnturf-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  standalone: true,
  imports: [AsyncPipe, AuthModalsComponent, IonicModule, NgIf, RouterLink, RouterLinkActive],
})
export class AdminHeaderComponent implements OnInit {
  loggedIn$: Observable<boolean>;

  constructor(
    private authModalFacade: AuthModalFacade,
    private statusFacade: StatusFacade,
  ) {}

  ngOnInit() {
    this.loggedIn$ = this.statusFacade.loggedIn$;
  }

  openLoginModal() {
    this.authModalFacade.openAuthModal('login');
  }
}
