import { Injectable } from '@angular/core';
import { Amenity, IMapPoint, ISite, ISiteWhere, ISubPhoto, Policy, SignalReception, Site } from '@curbnturf/entities';
import { LoadSiteRatings } from '@curbnturf/rating';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { newSite } from '../site-helpers';
import * as SiteActions from './site.actions';
import { SiteState } from './site.reducer';
import * as SiteSelectors from './site.selectors';

@Injectable({ providedIn: 'root' })
export class SiteFacade {
  sites$: Observable<Site[]>;
  entities$: Observable<{ [id: number]: Site }>;
  sitesReadyToEnable$: Observable<Site[]>;
  standardSites$: Observable<Site[]>;
  boondockSites$: Observable<Site[]>;
  currentUserSites$: Observable<Site[]> = of([]);
  selected$: Observable<Site | null>;
  selectedOnce$: Observable<Site | null>;
  error$: Observable<string | undefined>;
  loaded$: Observable<boolean>;

  constructor(private store: Store<SiteState>) {
    this.sites$ = this.store.pipe(select(SiteSelectors.getSites));
    this.entities$ = this.store.pipe(select(SiteSelectors.getSiteEntities));
    this.sitesReadyToEnable$ = this.store.pipe(select(SiteSelectors.getSitesReadyToEnable));
    this.standardSites$ = this.store.pipe(select(SiteSelectors.getStandardSites));
    this.boondockSites$ = this.store.pipe(select(SiteSelectors.getBoondockSites));
    this.currentUserSites$ = this.store.pipe(select(SiteSelectors.getCurrentUserSites));
    this.selected$ = this.store.pipe(select(SiteSelectors.getSelectedSite));
    this.selectedOnce$ = this.selected$.pipe(
      skipWhile((site) => !site || !site.id),
      take(1),
    );
    this.error$ = this.store.pipe(select(SiteSelectors.getError));
    this.loaded$ = this.store.pipe(select(SiteSelectors.getLoaded));
  }

  create(site?: ISite) {
    this.store.dispatch(SiteActions.createSite({ site: newSite(site) }));
  }

  createAmenity(site: ISite) {
    this.store.dispatch(SiteActions.createAmenity({ site }));
  }

  createPolicy(site: ISite) {
    this.store.dispatch(SiteActions.createPolicy({ site }));
  }

  createSignalReception(site: ISite) {
    this.store.dispatch(SiteActions.createSignalReception({ site }));
  }

  remove(id: number | string) {
    this.store.dispatch(SiteActions.removeSite({ siteId: id }));
  }

  restore(id: number) {
    this.store.dispatch(SiteActions.restoreSite({ siteId: id }));
  }

  removeAmenity(site: ISite, amenity: Amenity) {
    this.store.dispatch(SiteActions.removeAmenity({ site, amenity }));
  }

  removePolicy(site: ISite, policy: Policy) {
    this.store.dispatch(SiteActions.removePolicy({ site, policy }));
  }

  removeSignalReception(site: ISite, signalReception: SignalReception) {
    this.store.dispatch(SiteActions.removeSignalReception({ site, signalReception }));
  }

  update(site: ISite) {
    this.store.dispatch(SiteActions.updateSite({ site }));
  }

  select(siteId?: number) {
    this.store.dispatch(SiteActions.selectSite({ siteId }));
  }

  selectSite(site: ISite) {
    this.store.dispatch(SiteActions.selectSiteBySite({ site }));
  }

  loadRatings(id: number) {
    this.store.dispatch(new LoadSiteRatings(id));
  }

  removePhoto(siteId: number, index: number): void {
    this.store.dispatch(SiteActions.removeImageFromSite({ siteId, index }));
  }

  enable(site: ISite) {
    this.store.dispatch(SiteActions.enableSite({ site }));
  }

  enableAndHide(site: ISite) {
    this.store.dispatch(SiteActions.enableAndHideSite({ site }));
  }

  disable(site: ISite) {
    this.store.dispatch(SiteActions.disableSite({ site }));
  }

  hide(site: ISite) {
    this.store.dispatch(SiteActions.hideSite({ site }));
  }

  show(site: ISite) {
    this.store.dispatch(SiteActions.showSite({ site }));
  }

  loaded(site: ISite) {
    this.store.dispatch(SiteActions.siteLoaded({ site }));
  }

  loadUserSites(userId?: number) {
    if (userId) {
      this.store.dispatch(SiteActions.loadSites({ payload: { userId } }));
    } else {
      this.store.dispatch(SiteActions.loadSites({}));
    }
  }

  loadAllSites(where?: ISiteWhere) {
    this.store.dispatch(SiteActions.loadSites({ payload: where }));
  }

  load(siteId: number) {
    this.store.dispatch(SiteActions.loadSite({ siteId }));
  }

  clear() {
    this.store.dispatch(SiteActions.clearSites());
  }

  favorite(point: IMapPoint) {
    this.store.dispatch(SiteActions.favoriteSite({ point }));
  }

  unfavorite(point: IMapPoint) {
    this.store.dispatch(SiteActions.unfavoriteSite({ point }));
  }

  recropImage(siteToSave: ISite, photo: ISubPhoto) {
    this.store.dispatch(SiteActions.recropImage({ siteToSave, photo }));
  }
}
