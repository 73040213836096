import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlSerializer } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ActivityModule } from '@curbnturf/activity';
import { AlertModule } from '@curbnturf/alert';
import { AuthModule } from '@curbnturf/auth';
import { AuthModalModule } from '@curbnturf/auth-modal';
import { authReducer, logoutMetaReducer } from '@curbnturf/auth/src/lib/+state/auth.reducer';
import { NGRX_BUCKETS } from '@curbnturf/entities';
import { environment } from '@curbnturf/environment';
import { MessageModule } from '@curbnturf/message';
import { NetworkModule } from '@curbnturf/network';
import { OrderModule } from '@curbnturf/order';
import { orderReducer } from '@curbnturf/order/src/lib/+state/order.reducer';
import { ReservationModule } from '@curbnturf/reservation';
import { reservationReducer } from '@curbnturf/reservation/src/lib/+state/reservation.reducer';
import { ScheduleModule } from '@curbnturf/schedule';
import { scheduleReducer } from '@curbnturf/schedule/src/lib/+state/schedule.reducer';
import { SignUpStateModule } from '@curbnturf/sign-up-state';
import { SiteModule } from '@curbnturf/site';
import { siteReducer } from '@curbnturf/site/src/lib/+state/site.reducer';
import { StatusFacade } from '@curbnturf/status';
import { metaStorageReducer, StorageModule } from '@curbnturf/storage';
import { TripPlannerModule } from '@curbnturf/trip-planner';
import { UrlControlModule } from '@curbnturf/url-control';
import { urlControlReducer } from '@curbnturf/url-control/src/lib/+state/url-control.reducer';
import { UserModule } from '@curbnturf/user';
import { userReducer } from '@curbnturf/user/src/lib/+state/user.reducer';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { provideEffects } from '@ngrx/effects';
import { ActionReducerMap, MetaReducer, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import player, { LottiePlayer } from 'lottie-web';
import { DragulaModule } from 'ng2-dragula';
import { LottieModule } from 'ngx-lottie';
import { appRoutes } from './app-routes';
import { LowerCaseUrlSerializer } from './lowercase-serializer';

export function playerFactory(): LottiePlayer {
  return player;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducers: ActionReducerMap<any> = {
  [NGRX_BUCKETS.auth]: authReducer,
  [NGRX_BUCKETS.order]: orderReducer,
  [NGRX_BUCKETS.reservation]: reservationReducer,
  [NGRX_BUCKETS.schedule]: scheduleReducer,
  [NGRX_BUCKETS.site]: siteReducer,
  [NGRX_BUCKETS.urlControl]: urlControlReducer,
  [NGRX_BUCKETS.user]: userReducer,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const metaReducers: MetaReducer<any, any>[] = [logoutMetaReducer, metaStorageReducer];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const providers: any[] = [
  {
    provide: UrlSerializer,
    useClass: LowerCaseUrlSerializer,
  },
  provideAnimations(),
  provideRouter(appRoutes),
  provideClientHydration(),
  provideStore(reducers, {
    metaReducers,
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true,
      strictStateSerializability: false,
      strictActionSerializability: false,
    },
    initialState: {},
  }),
  provideEffects(),
  provideStoreDevtools(),
  importProvidersFrom(
    ActivityModule,
    AlertModule,
    AuthModalModule,
    AuthModule,
    DragulaModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    MessageModule,
    NetworkModule,
    OrderModule,
    ReservationModule,
    ScheduleModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SignUpStateModule,
    StatusFacade,
    SiteModule,
    StorageModule,
    TripPlannerModule,
    UrlControlModule,
    UserModule,
  ),
];

export const appConfig: ApplicationConfig = {
  providers,
};
