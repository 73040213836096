import { inject } from '@angular/core';
import * as AlertActions from '@curbnturf/alert/src/lib/+state/alert.actions';
import { IPhoto, ISite, ISubPhoto, NGRX_NO_ACTION } from '@curbnturf/entities';
import { stringFromError } from '@curbnturf/form-helpers';
import { OfflineSiteDbSet } from '@curbnturf/network';
import * as NetworkSelectors from '@curbnturf/network/src/lib/network/+state/network.selectors';
import { CaretakerService } from '@curbnturf/rating';
import { GlobalSpinnerService } from '@curbnturf/ui/src/lib/spinner/global-spinner.service';
import * as UserActions from '@curbnturf/user/src/lib/+state/user.actions';
import * as UserSelectors from '@curbnturf/user/src/lib/+state/user.selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { OfflineSiteService } from '../offline-site.service';
import { SiteService } from '../site.service';
import * as SiteActions from './site.actions';
import * as SiteSelectors from './site.selectors';

export const createSite$ = createEffect(
  (
    actions$ = inject(Actions),
    siteService = inject(SiteService),
    store = inject(Store),
    offlineSiteService = inject(OfflineSiteService),
  ) =>
    actions$.pipe(
      ofType(SiteActions.createSite),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      withLatestFrom(store.select(UserSelectors.getCurrentUser)),
      switchMap(([[action, status], currentUser]) => {
        const site: ISite = { ...action.site, user: currentUser };
        if (status.connected) {
          return siteService.create(site).pipe(
            mergeMap((createdSite: ISite) => [
              SiteActions.siteCreated({ site: createdSite }),
              SiteActions.siteLoaded({ site: createdSite }),
              SiteActions.selectSite({ siteId: createdSite.id }),
              UserActions.setInProgressSite({ site: createdSite, user: currentUser }),
            ]),
            catchError((err) =>
              of(
                SiteActions.createSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Creating Site',
                }),
              ),
            ),
          );
        } else {
          // If Offline, use the alternate
          return offlineSiteService.create(site).pipe(
            mergeMap((createdSite: ISite) => [
              SiteActions.siteCreated({ site: createdSite }),
              SiteActions.siteLoaded({ site: createdSite }),
              SiteActions.selectSite({ siteId: createdSite.syncId }),
              UserActions.setInProgressSite({ site: createdSite, user: currentUser }),
            ]),
            catchError((err) =>
              of(
                SiteActions.createSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Creating Site',
                }),
              ),
            ),
          );
        }
      }),
    ),
  { functional: true },
);

export const removeImageFromSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.removeImageFromSite),
      switchMap((action) =>
        siteService.removeImage(action.siteId, action.index).pipe(
          mergeMap((photos: IPhoto[]) => {
            return [
              SiteActions.siteImagesUpdated({ id: action.siteId, photos }),
              AlertActions.displayAlert({
                title: 'Image Removed from Site',
                body: 'Removed Image',
                level: 'error',
              }),
            ];
          }),
          catchError((err) =>
            of(
              SiteActions.createSiteFailed({
                error: err?.error?.message || err?.message || err || 'Error Removing Image From Site',
              }),
            ),
          ),
        ),
      ),
    ),
  { functional: true },
);

export const removeSite$ = createEffect(
  (
    actions$ = inject(Actions),
    siteService = inject(SiteService),
    store = inject(Store),
    offlineSiteService = inject(OfflineSiteService),
  ) =>
    actions$.pipe(
      ofType(SiteActions.removeSite),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      switchMap(([action, status]) => {
        if (status.connected) {
          return siteService.remove(action.siteId as number).pipe(
            switchMap((removedSite: ISite) => {
              const actionArray: Action[] = [
                SiteActions.siteRemoved({
                  site: removedSite,
                }),
              ];

              if (removedSite) {
                actionArray.push(
                  AlertActions.displayAlert({
                    title: 'Site removed',
                    body: 'Removed Site',
                    level: 'error',
                  }),
                );
              }

              return actionArray;
            }),
            catchError((err) => [
              SiteActions.removeSiteFailed({
                error: err?.error?.message || err?.message || err || 'Error Removing Site',
              }),
            ]),
          );
        } else if (!status.connected) {
          return offlineSiteService.remove(action.siteId as string).pipe(
            switchMap((removedSite: ISite) => {
              const actionArray: Action[] = [
                SiteActions.siteRemoved({
                  site: removedSite,
                }),
              ];

              if (removedSite) {
                actionArray.push(
                  AlertActions.displayAlert({
                    title: 'Site removed',
                    body: 'Removed Site',
                    level: 'error',
                  }),
                );
              }

              return actionArray;
            }),
            catchError((err) => [
              SiteActions.removeSiteFailed({
                error: err?.error?.message || err?.message || err || 'Error Removing Site',
              }),
            ]),
          );
        }

        return of(
          SiteActions.removeSiteFailed({
            error: 'Error Removing Site - Could Not Match Connection State',
          }),
        );
      }),
      catchError((err) =>
        of(
          SiteActions.removeSiteFailed({
            error:
              err?.error?.message || err?.message || err || 'Error Removing Site - Could Not Match Connection State',
          }),
        ),
      ),
    ) as Observable<Action>,
  { functional: true },
);

export const restoreSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.restoreSite),
      switchMap((action) =>
        siteService.restore(action.siteId).pipe(
          switchMap((restoredSite: ISite) => {
            const actionArray: Action[] = [SiteActions.siteRestored({ site: restoredSite })];

            if (restoredSite) {
              actionArray.push(
                AlertActions.displayAlert({
                  title: 'Site restored',
                  body: 'Restored Site',
                  level: 'error',
                }),
              );
            }

            return actionArray;
          }),
          catchError((err) =>
            of(
              SiteActions.createSiteFailed({
                error: err?.error?.message || err?.message || err || 'Error Restoring Site',
              }),
            ),
          ),
        ),
      ),
      catchError((err) =>
        of(
          SiteActions.createSiteFailed({ error: err?.error?.message || err?.message || err || 'Error Restoring Site' }),
        ),
      ),
    ) as Observable<Action>,
  { functional: true },
);

export const loadSite$ = createEffect(
  (
    actions$ = inject(Actions),
    siteService = inject(SiteService),
    store = inject(Store),
    offlineSiteService = inject(OfflineSiteService),
  ) =>
    actions$.pipe(
      ofType(SiteActions.loadSite),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      switchMap(([action, status]) => {
        if (status.connected && typeof action.siteId === 'number') {
          return siteService.fetch(action.siteId).pipe(
            switchMap((site: ISite) => [SiteActions.siteLoaded({ site }), SiteActions.selectSite({ siteId: site.id })]),
            catchError((err) =>
              of(
                SiteActions.loadSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Loading Site',
                }),
              ),
            ),
          );
        } else if (typeof action.siteId === 'string') {
          return offlineSiteService.fetch(action.siteId).pipe(
            switchMap((site: ISite) => [
              SiteActions.siteLoaded({ site }),
              SiteActions.selectSite({ siteId: site.syncId }),
            ]),
            catchError((err) =>
              of(
                SiteActions.loadSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Loading Site',
                }),
              ),
            ),
          );
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
      catchError((err) =>
        of(SiteActions.loadSiteFailed({ error: err?.error?.message || err?.message || err || 'Error Loading Site' })),
      ),
    ),
  { functional: true },
);

export const loadSites$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService), store = inject(Store)) =>
    actions$.pipe(
      ofType(SiteActions.loadSites),
      switchMap((action) => {
        if (action.payload) {
          return siteService.fetchAll(action.payload).pipe(
            switchMap((result: { total: number; page: number; sites: ISite[] }) => [
              SiteActions.sitesLoaded({ sites: result.sites }),
            ]),
            catchError((err) =>
              of(
                SiteActions.loadSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Loading Sites',
                }),
              ),
            ),
          );
        }

        return store.pipe(select(UserSelectors.getCurrentId)).pipe(
          filter((userId) => !!userId),
          take(1),
          switchMap((userId) => {
            if (!userId) {
              return [SiteActions.loadSiteFailed({ error: 'No user id provided' })];
            }

            return siteService.fetchAll({ userId }).pipe(
              switchMap((result: { total: number; page: number; sites: ISite[] }) => [
                SiteActions.sitesLoaded({ sites: result.sites }),
              ]),
              catchError((err) => [
                SiteActions.loadSiteFailed({ error: err?.error?.message || err || 'Error Loading Sites' }),
              ]),
            );
          }),
          catchError((err) => [
            SiteActions.loadSiteFailed({ error: err?.error?.message || err?.message || err || 'Error Loading Sites' }),
          ]),
        );
      }),
      catchError((err) => [SiteActions.loadSiteFailed({ error: err?.error?.message || err || 'Error Loading Sites' })]),
    ),
  { functional: true },
);

// using mergeMap to allow multiple updates at a time
export const updateSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService), store = inject(Store)) =>
    actions$.pipe(
      ofType(SiteActions.updateSite),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      mergeMap(([action, status]) => {
        if (status.connected) {
          return siteService.update(action.site).pipe(
            mergeMap((updatedSite: ISite) => [SiteActions.siteLoaded({ site: updatedSite })]),
            catchError((err) => [
              SiteActions.updateSiteFailed({ error: err?.error?.message || err || 'Error Updating Site' }),
            ]),
          );
        } else {
          // If Offline, we handle this elsewhere
          return [NGRX_NO_ACTION];
        }
      }),
      catchError((err) => [
        SiteActions.updateSiteFailed({ error: err?.error?.message || err || 'Error Updating Site' }),
      ]),
    ),
  { functional: true },
);

// using mergeMap to allow multiple updates at a time
export const updateSiteOffline$ = createEffect(
  (actions$ = inject(Actions), offlineSiteDbSet = inject(OfflineSiteDbSet)) =>
    actions$.pipe(
      ofType(SiteActions.updateSiteOffline),
      mergeMap((action) => {
        return of(offlineSiteDbSet.store(action.payload)).pipe(
          mergeMap(() => [NGRX_NO_ACTION]),
          catchError((err) => [
            SiteActions.updateSiteFailed({ error: err?.error?.message || err || 'Error Updating Site' }),
          ]),
        );
      }),
      catchError((err) => [
        SiteActions.updateSiteFailed({ error: err?.error?.message || err || 'Error Updating Site' }),
      ]),
    ),
  { functional: true },
);

export const enableSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.enableSite),
      switchMap((action) => {
        if (action.site) {
          return siteService.enable(action.site).pipe(
            mergeMap((result: { success: boolean; message: string }) => {
              if (result.success && action.site?.id) {
                return [
                  AlertActions.displayAlert({
                    title: 'Listing Published',
                    body: `Published ${action.site.name}`,
                  }),
                  SiteActions.siteEnabled({ siteId: action.site.id }),
                ];
              }
              return [
                SiteActions.siteEnabledFailed({
                  body: `Unable to publish listing for the following reasons:\n${result.message}`,
                  siteId: action.site.id,
                }),
              ];
            }),
            catchError((err) => [
              SiteActions.siteEnabledFailed({
                body: err?.error?.message || err?.message || err || 'Error Enabling Site',
              }),
            ]),
          );
        } else {
          return [
            SiteActions.siteEnabledFailed({
              body: 'Site Record Not Provided',
            }),
          ];
        }
      }),
      catchError((err) => [
        SiteActions.siteEnabledFailed({
          body: err?.error?.message || err?.message || err || 'Error Enabling Site',
        }),
      ]),
    ),
  { functional: true },
);

export const enableAndHideSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.enableAndHideSite),
      switchMap((action) =>
        siteService.enableAndHide(action.site).pipe(
          mergeMap((result: { success: boolean; message: string }) => {
            if (result.success) {
              if (action.site.id) {
                return [
                  AlertActions.displayAlert({
                    title: 'Listing Hidden',
                    body: `Hid ${action.site.name}`,
                  }),
                  SiteActions.siteEnabled({
                    siteId: action.site.id,
                  }),
                ];
              } else {
                return [
                  SiteActions.siteEnabledFailed({
                    body: `Unable to hide listing for the following reasons:\n${result.message}`,
                    siteId: action.site.id,
                  }),
                ];
              }
            }
            return [
              SiteActions.siteEnabledFailed({
                body: `Unable to hide listing for the following reasons:\n${result.message}`,
                siteId: action.site.id,
              }),
            ];
          }),
          catchError((err) => [
            SiteActions.siteEnabledFailed({
              body: err?.error?.message || err?.message || err || 'Error Making Site Ready to Show',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.siteEnabledFailed({
          body: err?.error?.message || err?.message || err || 'Error Enabling Site',
        }),
      ]),
    ),
  { functional: true },
);

export const disableSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.disableSite),
      switchMap((action) =>
        siteService.disable(action.site).pipe(
          mergeMap((result: { success: boolean; message: string }) => {
            if (result.success && action.site.id) {
              return [
                AlertActions.displayAlert({
                  title: 'Listing Disabled',
                  body: `Disabled ${action.site.name}`,
                }),
                SiteActions.siteDisabled({
                  siteId: action.site.id,
                }),
              ];
            }
            return [
              SiteActions.siteDisabledFailed({
                error: `Unable to disable listing for the following reasons:\n${result.message}`,
              }),
            ];
          }),
          catchError((err) => [
            SiteActions.siteDisabledFailed({
              error: err?.error?.message || err?.message || err || 'Error Disabling Site',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.siteEnabledFailed({
          body: err?.error?.message || err?.message || err || 'Error Enabling Site',
        }),
      ]),
    ),
  { functional: true },
);

export const createSiteAmenity$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.createAmenity),
      switchMap((action) =>
        siteService.createAmenity(action.site).pipe(
          mergeMap((updatedSite: ISite) => [
            SiteActions.siteLoaded({
              site: updatedSite,
            }),
          ]),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Creating Site Amenity',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Creating Site Amenity',
        }),
      ]),
    ),
  { functional: true },
);

export const removeSiteAmenity$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.removeAmenity),
      switchMap((action) =>
        siteService.removeAmenity(action.site, action.amenity).pipe(
          mergeMap((updatedSite: ISite) => [
            SiteActions.siteLoaded({
              site: updatedSite,
            }),
          ]),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Removing Site Amenity',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Removing Site Amenity',
        }),
      ]),
    ),
  { functional: true },
);

export const createSitePolicy$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.createPolicy),
      switchMap((action) =>
        siteService.createPolicy(action.site).pipe(
          mergeMap((updatedSite: ISite) => [
            SiteActions.siteLoaded({
              site: updatedSite,
            }),
          ]),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Creating Site Policy',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Creating Site Policy',
        }),
      ]),
    ),
  { functional: true },
);

export const removeSitePolicy$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.removePolicy),
      switchMap((action) =>
        siteService.removePolicy(action.site, action.policy).pipe(
          mergeMap((updatedSite: ISite) => [
            SiteActions.siteLoaded({
              site: updatedSite,
            }),
          ]),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Removing Site Policy',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Removing Site Policy',
        }),
      ]),
    ),
  { functional: true },
);

export const createSiteSignalReception$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.createSignalReception),
      switchMap((action) =>
        siteService.createSignalReception(action.site).pipe(
          mergeMap((updatedSite: ISite) => [
            SiteActions.siteLoaded({
              site: updatedSite,
            }),
          ]),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Creating Site Signal Reception',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Creating Site Signal Reception',
        }),
      ]),
    ),
  { functional: true },
);

export const removeSiteSignalReception$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.removeSignalReception),
      switchMap((action) =>
        siteService.removeSignalReception(action.site, action.signalReception).pipe(
          mergeMap((updatedSite: ISite) => [
            SiteActions.siteLoaded({
              site: updatedSite,
            }),
          ]),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Removing Site Signal Reception',
            }),
          ]),
        ),
      ),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Removing Site Signal Reception',
        }),
      ]),
    ),
  { functional: true },
);

export const uploadSiteImage$ = createEffect(
  (
    actions$ = inject(Actions),
    siteService = inject(SiteService),
    store = inject(Store),
    spinnerService = inject(GlobalSpinnerService),
    offlineSiteService = inject(OfflineSiteService),
  ) =>
    actions$.pipe(
      ofType(SiteActions.uploadSiteImage),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      switchMap(([action, status]) => {
        const payload = action;
        if (!payload || !payload.formData || !payload.data || (!payload.data.siteId && !payload.data.syncId)) {
          return of(NGRX_NO_ACTION);
        }
        if (status.connected && payload.data.siteId) {
          const files = payload.formData;
          const siteId = payload.data.siteId;
          spinnerService.spinnerOn();
          return siteService.uploadImage(siteId, files).pipe(
            switchMap((results: { id: number; photos: IPhoto[] }) => {
              spinnerService.spinnerOff();
              return [
                SiteActions.siteImagesUpdated({
                  ...results,
                }),
              ];
            }),
            catchError((err) => {
              spinnerService.spinnerOff();

              return [
                SiteActions.createSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Uploading Site Image',
                }),
              ];
            }),
          );
        } else if (!status.connected && payload.data.syncId) {
          const files = payload.formData;
          const syncId = payload.data.syncId;
          spinnerService.spinnerOn();
          return offlineSiteService.uploadImage(syncId, files).pipe(
            switchMap((results: { syncId: string; photos: IPhoto[] }) => {
              spinnerService.spinnerOff();
              return [
                SiteActions.siteImagesUpdated({
                  ...results,
                }),
              ];
            }),
            catchError((err) => {
              spinnerService.spinnerOff();

              return [
                SiteActions.createSiteFailed({
                  error: err?.error?.message || err?.message || err || 'Error Uploading Site Image',
                }),
              ];
            }),
          );
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
      catchError((err) => {
        spinnerService.spinnerOff();

        return [
          SiteActions.createSiteFailed({
            error: err?.error?.message || err?.message || err || 'Error Uploading Site Image',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const recropSiteImage$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService), store = inject(Store)) =>
    actions$.pipe(
      ofType(SiteActions.recropImage),
      withLatestFrom(store.select(NetworkSelectors.getStatus)),
      switchMap(([action, status]) => {
        if (!action || !action.siteToSave || !action.siteToSave.photos || !action.photo) {
          return of(NGRX_NO_ACTION);
        }

        const site: ISite = { ...action.siteToSave, photos: [...action.siteToSave.photos] };
        const photo: ISubPhoto = action.photo;
        if (site.photos) {
          const foundPhotoIndex = site.photos.findIndex((el) => el.key === photo.key);
          if (foundPhotoIndex >= 0) {
            const newPhoto = { ...photo, key: site.photos[foundPhotoIndex].key };
            site.photos.splice(foundPhotoIndex, 1, newPhoto);
          } else {
            return of(SiteActions.recropImageFailure({ error: 'Failed to find the image to crop.' }));
          }
        } else {
          return of(SiteActions.recropImageFailure({ error: 'Failed to find the image to crop.' }));
        }

        if (status.connected && site.id) {
          return siteService.update(site).pipe(
            mergeMap((updatedSite: ISite) => [
              SiteActions.siteLoaded({ site: updatedSite }),
              SiteActions.siteImagesUpdated({ id: updatedSite.id, photos: updatedSite.photos ?? [] }),
            ]),
            catchError((err) => [
              SiteActions.updateSiteFailed({ error: err?.error?.message || err || 'Error Updating Site' }),
            ]),
          );
        } else {
          return of(NGRX_NO_ACTION);
        }
      }),
      catchError((err) => {
        return [
          SiteActions.createSiteFailed({
            error: err?.error?.message || err?.message || err || 'Error Uploading Site Image',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const uploadBoondockReviewImage$ = createEffect(
  (
    actions$ = inject(Actions),
    caretakerService = inject(CaretakerService),
    spinnerService = inject(GlobalSpinnerService),
  ) =>
    actions$.pipe(
      ofType(SiteActions.uploadBoondockReviewImage),
      switchMap((action) => {
        if (!action || !action.formData || !action.data || !action.data.siteId) {
          return of(NGRX_NO_ACTION);
        }
        const files = action.formData;
        const siteId = action.data.siteId;
        spinnerService.spinnerOn();
        return caretakerService.uploadImage(siteId, files).pipe(
          switchMap((results: { id: number; photos: IPhoto[] }) => {
            spinnerService.spinnerOff();
            return [
              SiteActions.boondockReviewImageUploaded({
                ...results,
              }),
            ];
          }),
          catchError((err) => {
            spinnerService.spinnerOff();

            return [
              SiteActions.boondockReviewImageUploadFailed({
                error: err?.error?.message || err?.message || err || 'Error Uploading Boondock Review Image',
              }),
            ];
          }),
        );
      }),
      catchError((err) => {
        spinnerService.spinnerOff();

        return [
          SiteActions.boondockReviewImageUploadFailed({
            error: err?.error?.message || err?.message || err || 'Error Uploading Boondock Review Image',
          }),
        ];
      }),
    ),
  { functional: true },
);

export const selectSite$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) =>
    actions$.pipe(
      ofType(SiteActions.selectSite),
      withLatestFrom(store.select(SiteSelectors.getSites)),
      switchMap(([action, sites]) => {
        if (!action.siteId) {
          return [SiteActions.siteSelected({ site: undefined })];
        }

        const id = action.siteId;
        const foundSite = sites.find(
          (el: ISite) => (el.id && id && el.id === id) || (el.syncId && id && el.syncId === id),
        );
        if (foundSite) {
          return [SiteActions.siteSelected({ site: foundSite })];
        }
        return [SiteActions.loadSite({ siteId: id })];
      }),
      catchError((err) =>
        of(SiteActions.loadSiteFailed({ error: err?.error?.message || err?.message || err || 'Error Selecting Site' })),
      ),
    ),
  { functional: true },
);

export const createSiteFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.createSiteFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Creating Site',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const removeSiteFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.removeSiteFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Removing Site',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const loadSiteFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.loadSiteFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Loading Sites',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const updateSiteFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.updateSiteFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Updating Site',
          body: stringFromError(action.error),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const siteEnabledFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.siteEnabledFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Enabling Listing',
          body: action.body?.split('\n').join('<br> - '),
          level: 'error',
          noTimeout: true,
        }),
      ),
    ),
  { functional: true },
);

export const siteDisabledFailed$ = createEffect(
  (actions$ = inject(Actions)) =>
    actions$.pipe(
      ofType(SiteActions.siteDisabledFailed),
      map((action) =>
        AlertActions.displayAlert({
          title: 'Error Disabling Listing',
          body: action.error?.split('\n').join('<br> - '),
          level: 'error',
        }),
      ),
    ),
  { functional: true },
);

export const favoriteSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.favoriteSite),
      switchMap((action) => {
        if (!action.point) {
          return [
            SiteActions.siteFavorFailed({
              error: 'No site to favorite',
            }),
          ];
        }

        return siteService.favorite(action.point).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                SiteActions.siteFavorFailed({
                  error: successResult.message,
                }),
              ];
            }

            return [
              SiteActions.siteFavorCompleted({
                id: action.point.id || 0,
                favored: true,
              }),
            ];
          }),
          catchError((err) => [
            SiteActions.siteFavorFailed({
              error: err?.error?.message || err?.message || err || 'Error Favoriting Site',
            }),
          ]),
        );
      }),
      catchError((err) => [
        SiteActions.siteFavorFailed({
          error: err?.error?.message || err?.message || err || 'Error Favoriting Site',
        }),
      ]),
    ),
  { functional: true },
);

export const hideSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.hideSite),
      switchMap((action) => {
        if (!action.site) {
          return [
            SiteActions.updateSiteFailed({
              error: 'Unable to hide site',
            }),
          ];
        }

        return siteService.hide(action.site).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                SiteActions.updateSiteFailed({
                  error: successResult.message,
                }),
              ];
            }

            return [
              SiteActions.siteUpdated({
                site: { ...action.site, hidden: true },
              }),
            ];
          }),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Hiding Site',
            }),
          ]),
        );
      }),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Hiding Site',
        }),
      ]),
    ),
  { functional: true },
);

export const showSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.showSite),
      switchMap((action) => {
        if (!action.site) {
          return [
            SiteActions.updateSiteFailed({
              error: 'Unable to show site',
            }),
          ];
        }

        return siteService.show(action.site).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                SiteActions.updateSiteFailed({
                  error: successResult.message,
                }),
              ];
            }

            return [
              SiteActions.siteUpdated({
                site: { ...action.site, hidden: false },
              }),
            ];
          }),
          catchError((err) => [
            SiteActions.updateSiteFailed({
              error: err?.error?.message || err?.message || err || 'Error Showing Site',
            }),
          ]),
        );
      }),
      catchError((err) => [
        SiteActions.updateSiteFailed({
          error: err?.error?.message || err?.message || err || 'Error Showing Site',
        }),
      ]),
    ),
  { functional: true },
);

export const unfavoriteSite$ = createEffect(
  (actions$ = inject(Actions), siteService = inject(SiteService)) =>
    actions$.pipe(
      ofType(SiteActions.unfavoriteSite),
      switchMap((action) => {
        if (!action.point) {
          return [
            SiteActions.siteFavorFailed({
              error: 'No site to unfavorite',
            }),
          ];
        }

        return siteService.unfavorite(action.point).pipe(
          mergeMap((successResult: { success: boolean; message: string }) => {
            if (!successResult.success) {
              return [
                SiteActions.siteFavorFailed({
                  error: successResult.message,
                }),
              ];
            }

            return [SiteActions.siteFavorCompleted({ id: action.point.id || 0, favored: false })];
          }),
          catchError((err) => [
            SiteActions.siteFavorFailed({
              error: err?.error?.message || err?.message || err || 'Error Unfavoring Site',
            }),
          ]),
        );
      }),
      catchError((err) => [
        SiteActions.siteFavorFailed({
          error: err?.error?.message || err?.message || err || 'Error Unfavoring Site',
        }),
      ]),
    ),
  { functional: true },
);
